import React, { useState, useEffect, useRef } from 'react'
import { DashboardMainFlex, DashboardMainBody, MenuPopUp, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import moment from 'moment'
import CommaNumber from 'comma-number'
import axios from 'axios'
import Loader from '../reusable/loader'
import EmptyState from '../reusable/emptyState'
import { useNavigate } from 'react-router'
import CsvDownloader from 'react-csv-downloader'
import BreadcrumbArea from '../reusable/breadcrumb'
import { handleBg, handleColor } from '../../utils/colorHandle'
import PaginationComp from '../reusable/pagination'
import RangeModal from '../reusable/rangeModal'
import { paginationItemClasses } from '@mui/material'

const TransactionsMain = () => {
  const date = new Date()
  const navigate = useNavigate()
  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const exportButton: any = useRef(null)

  // States
  const [searchedItem, setSearchedItem] = useState('')
  const [activeItem, setActiveItem] = useState(-1)
  const [transactions, setTransactions] = useState<any>('')
  const [transactionsMutable, setMutableTransactions] = useState<any>([])
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [openExport, setOpenExport] = useState(false)
  const [exportData, setExportData] = useState([])
  const [onProcess, setOnProcess] = useState(false)

  // Export Range

  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  const getRangeData = (e: any) => {
    e.preventDefault()
    if (startDate && endDate) {
      setOnProcess(true)

      axios.get(`${process.env.REACT_APP_BASE_URL}/range/transaction/${new Date(startDate).getTime()}/${new Date(endDate).getTime()}`, body)
        .then(res => {
          setOnProcess(false)
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement)
          }
        }).catch(err => {
          setOnProcess(false)
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/search/transactions?limit=20&page=${page}&key=${searchedItem}`, body)
        .then(res => {
          setTransactions(res.data.data)
          setMutableTransactions(res.data.data)
          setTotalData(res.data.data.length)
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click()
      setOpenExport(false)
    }
  }, [exportData])

  // Call Function
  const getTransactions = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transaction/all?limit=20&page=${page}`, body)
      .then(res => {
        setTransactions(res.data.data.data)
        setMutableTransactions(res.data.data.data)
        setTotalData(res.data.data.total)
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  // Get Transactions
  useEffect(() => {
    getTransactions()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getTransactions()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getTransactions()
    }
  }

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem('')
    getTransactions()
  }

  return (
        <>
            {
                openExport
                  ? <RangeModal
                        closeFunc={() => { setOpenExport(false) }}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        action={getRangeData}
                        onProcess={onProcess}
                    />
                  : null
            }
            <DashboardMainFlex onClick={() => { setActiveItem(-1) }}>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Transactions'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Transactions ({totalData})</h3>
                        </DescHeader>
                        <HeaderItems>
                            <DashboardInput
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input
                                    placeholder='Search transactions'
                                    value={searchedItem}
                                    onChange={(e) => {
                                      setSearchedItem(e.target.value)
                                    }}
                                />
                                <i>
                                    <Icon.SearchNormal1
                                        size={18}
                                        onClick={() => { searchItem() }}
                                    />
                                </i>
                            </DashboardInput>
                            <CsvDownloader
                                    datas={exportData}
                                    filename={`Transactions - ${moment(date)}`}

                                >
                                    <button ref={exportButton} style={{ visibility: 'hidden' }}>

                                    </button>
                                </CsvDownloader>
                                <FlexedBtn
                                    onClick={() => { setOpenExport(true) }}
                                >
                                    <button>
                                        <Icon.DocumentDownload />
                                        Export
                                    </button>
                                </FlexedBtn>
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                                onClick={() => { refreshFilter() }}
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                            <TableFlex>
                                <SmallTableSpace>
                                    <h3>I.D</h3>
                                </SmallTableSpace>
                                <DateWrap>
                                    <h3>Date</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Sender Details</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Receiver Details</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Reference</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <h3>Amount</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <p>Status</p>
                                </SmallTableSpace>
                            </TableFlex>
                            <Line />
                            {
                                transactions !== ''
                                  ? transactionsMutable && transactionsMutable.length > 0
                                    ? <>
                                        {
                                            transactionsMutable.map((item: any, index: number) => (
                                                <TableFlex
                                                    key={index}
                                                >
                                                    <SmallTableSpace>
                                                        <h3>{item.transactionID}</h3>
                                                    </SmallTableSpace>
                                                    <DateWrap>
                                                        <p>{moment(item.Date).format('LLL')}</p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        {
                                                            item.statusType && item.statusType.toLowerCase() === 'credit' || item.serviceType === 'Deposit'
                                                              ? <UserProfileCard>
                                                                    <div>
                                                                        <span
                                                                            style={{
                                                                              color: '#161616',
                                                                              fontSize: '12px'
                                                                            }}
                                                                        >{item.fullName}</span>
                                                                        <span
                                                                            style={{
                                                                              color: '#3B4CB8',
                                                                              fontSize: '10px',
                                                                              margin: '10px 0 0 0'
                                                                            }}
                                                                        >{item.bankName}</span>
                                                                        <h3
                                                                            style={{
                                                                              color: '#161616',
                                                                              fontSize: '10px',
                                                                              fontWeight: '400',
                                                                              lineHeight: '20px'
                                                                            }}
                                                                        >
                                                                            {item.accountNumber ? `${item.accountNumber}` : ''}
                                                                        </h3>
                                                                    </div>
                                                                </UserProfileCard>
                                                              : <UserProfileCard>
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                          color: '#161616',
                                                                          fontSize: '12px'
                                                                        }}
                                                                    >{item.User && item.User.firstName} {item.User && item.User.surname}</span>
                                                                </div>
                                                            </UserProfileCard>
                                                        }
                                                    </DateWrap>
                                                    <DateWrap>
                                                        {item.statusType && item.statusType.toLowerCase() === 'debit' || item.serviceType === 'Transfer'
                                                          ? <UserProfileCard>
                                                             <div>
                                                                 <span
                                                                     style={{
                                                                       color: '#161616',
                                                                       fontSize: '12px'
                                                                     }}
                                                                 >{item.fullName}</span>
                                                                 <span
                                                                     style={{
                                                                       color: '#3B4CB8',
                                                                       fontSize: '10px',
                                                                       margin: '10px 0 0 0'
                                                                     }}
                                                                 >{item.bankName}</span>
                                                                 <h3
                                                                     style={{
                                                                       color: '#161616',
                                                                       fontSize: '10px',
                                                                       fontWeight: '400',
                                                                       lineHeight: '20px'
                                                                     }}
                                                                 >
                                                                     {item.accountNumber ? `${item.accountNumber}` : ''}
                                                                 </h3>
                                                             </div>
                                                         </UserProfileCard>
                                                          : <UserProfileCard>
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                          color: '#161616',
                                                                          fontSize: '12px'
                                                                        }}
                                                                    >{item.User && item.User.firstName} {item.User && item.User.surname}</span>
                                                                </div>
                                                            </UserProfileCard>
                                                        }
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <p>{item.reference}/{item.narration}</p>
                                                    </DateWrap>
                                                    <SmallTableSpace>
                                                        <p
                                                            style={
                                                                item.statusType === 'Debit' || item.serviceType === 'Transfer'
                                                                  ? {
                                                                      color: '#F42F4B'
                                                                    }
                                                                  : {
                                                                      color: '#4DC736'
                                                                    }
                                                            }
                                                        >
                                                            {item.statusType === 'Debit' || item.serviceType === 'Transfer' ? '-' : ''}&#8358;
                                                            {CommaNumber(item.amount)}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <StatusCard
                                                            bg={`${handleBg(item.status)}`}
                                                            color={`${handleColor(item.status)}`}
                                                        >
                                                            {item.status}
                                                        </StatusCard>
                                                    </SmallTableSpace>
                                                </TableFlex>
                                            ))
                                        }
                                        <PaginationComp
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default TransactionsMain
