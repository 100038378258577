import React, { useState, useEffect, useRef } from 'react'
import { DashboardMainFlex, DashboardMainBody, MenuPopUp, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as FeatherIcon from 'react-feather'
import moment from 'moment'
import axios from 'axios'
import Loader from '../reusable/loader'
import EmptyState from '../reusable/emptyState'
import { useNavigate, useParams } from 'react-router'
import BreadcrumbArea from '../reusable/breadcrumb'
import PaginationComp from '../reusable/pagination'
import { ClipLoader } from 'react-spinners'

const BlockedUsersMain = () => {
  const navigate = useNavigate()
  const { index } = useParams()
  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const exportButton: any = useRef(null)

  // States
  const [activeItem, setActiveItem] = useState(-1)
  const [blockedUsers, setBlockedUsers] = useState<any>('')
  const [blockedUsersMutable, setMutableblockedUsers] = useState<any>([])
  const [email, setEmail] = useState('')
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')
  const [openExport, setOpenExport] = useState(false)
  const [exportData, setExportData] = useState([])
  const [onProcess, setOnProcess] = useState(false)

  // Export Range

  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click()
      setOpenExport(false)
    }
  }, [exportData])

  const getText = () => {
    switch (index) {
      case '1':
        return 'Transfers'
      case '2':
        return 'App Access'
      case '3':
        return 'P2P Transfers'
      default:
        break
    }
  }

  const basicPayload = {
    type: `${index}`,
    email
  }

  // Call Function
  const getBlockedUsers = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/panel/block/all?limit=20&page=${page}`, body)
      .then(res => {
        if (index === '1') {
          setBlockedUsers(res.data.data.transferUser)
          setMutableblockedUsers(res.data.data.transferUser)
          setTotalData(res.data.data.transferUser.length)
        } else if (index === '2') {
          setBlockedUsers(res.data.data.accountUser)
          setMutableblockedUsers(res.data.data.accountUser)
          setTotalData(res.data.data.accountUser.length)
        } else if (index === '3') {
          setBlockedUsers(res.data.data.mongoroUser)
          setMutableblockedUsers(res.data.data.mongoroUser)
          setTotalData(res.data.data.mongoroUser.length)
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  // Get Transactions
  useEffect(() => {
    getBlockedUsers()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getBlockedUsers()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getBlockedUsers()
    }
  }

  const unblockUser = () => {
    setOnProcess(true)
    axios.post(`${process.env.REACT_APP_BASE_URL}/admin/panel/unblocking/`, basicPayload, {
      headers: {
        'x-token': `${loggedAdmin}`
      }
    }).then((res) => {
      setOnProcess(false)
      setActiveItem(-1)
      getBlockedUsers()
    }).catch((err) => {
      setOnProcess(false)
    })
  }

  return (
        <>
            <DashboardMainFlex onClick={() => { setActiveItem(-1) }}>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Blocking Activities'
                            redirectUrl='/blockings'
                        />
                        <DescHeader>
                            <h3>Blocked Users ({getText()})</h3>
                        </DescHeader>
                        <TableWrap>
                            <TableFlex>
                                <DateWrap>
                                    <h3>Date Blocked</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>User</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Email Address</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <p>Action</p>
                                </SmallTableSpace>
                            </TableFlex>
                            <Line />
                            {
                                blockedUsers !== ''
                                  ? blockedUsersMutable && blockedUsersMutable.length > 0
                                    ? <>
                                        {
                                            blockedUsersMutable.map((item: any, index: number) => (
                                                <TableFlex
                                                    key={index}
                                                >
                                                    <DateWrap>
                                                        <p>{moment(item.createdAt).format('LLL')}</p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <p>{item.firstName} {item.surname}</p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <p>{item.email}</p>
                                                    </DateWrap>
                                                    <SmallTableSpace>
                                                        <FeatherIcon.MoreVertical
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              setActiveItem(index)
                                                              setEmail(item.email)
                                                            }}
                                                            style={{
                                                              cursor: 'pointer'
                                                            }}
                                                        />
                                                    </SmallTableSpace>
                                                    {
                                                        activeItem === index
                                                          ? <MenuPopUp>
                                                                <p
                                                                    style={{
                                                                      color: '#F2000B'
                                                                    }}
                                                                    onClick={(e) => {
                                                                      e.stopPropagation()
                                                                      unblockUser()
                                                                    }}
                                                                >{onProcess ? <ClipLoader color='#F2000B' size={20} /> : 'Unblock User'}</p>
                                                            </MenuPopUp>
                                                          : null
                                                    }
                                                </TableFlex>
                                            ))
                                        }
                                        <PaginationComp
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default BlockedUsersMain
