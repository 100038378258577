import React from 'react'
import TicketInformation from '../components/tickets/single'

const SingleTicketsPage = () => {
  return (
        <>
            <TicketInformation />
        </>
  )
}

export default SingleTicketsPage
