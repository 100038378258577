import React, { useState, useEffect } from 'react'
import { InputField, DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, BalanceCard, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, Role, DateWrap, MenuSpace, UserCard, IconCard, BasicFlex, GreyText, UserProfileCard, GridTexts, ThreeSpaceGridWrap, SpaceWrap, HeroCover } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router'
import axios from 'axios'
import { InputWrap } from '../../styles/authentication'
import Loader from '../reusable/loader'
import { Link } from 'react-router-dom'
import { AvatarImage } from '../reusable/style'
import Alert from '../reusable/alert'
import { ClipLoader } from 'react-spinners'
import BreadcrumbArea from '../reusable/breadcrumb'
import { getKycStatus } from '../../utils/getKycStatus'
import CommaNumber from 'comma-number'

const ProfileIndex = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const loggedAdmin = localStorage.getItem('tks') || '{}'

  // States
  const [userInfo, setUserInfo] = useState<any>({})
  const [account, setAccount] = useState<any>({})

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [surname, setSurname] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [dob, setDob] = useState('')
  const [gender, setGender] = useState('')
  const [bvn, setBvn] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')

  const [onProcess, setOnprocess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'
    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    if (id) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/${id}`, body)
        .then(res => {
          if (res) {
            setFirstName(res.data.data.firstName)
            setLastName(res.data.data.middleName)
            setSurname(res.data.data.surname)
            setEmail(res.data.data.email)
            setDob(res.data.data.dob)
            setUsername(res.data.data.walletTag)
            setGender(res.data.data.gender)
            setPhone(res.data.data.phone)
            setAddress(res.data.data.address)
          }
          setUserInfo(res.data.data)
          setAccount(res.data.data.Accounts[0])
          setBvn(res.data.data.Bvn[0].data.idNumber)
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }, [id])

  // Edit User Information
  const basicPayload = {
    firstName,
    middleName: lastName,
    surname,
    dob,
    walletTag: username,
    gender,
    phone,
    address
  }

  const EditUser = () => {
    setOnprocess(true)
    axios.put(`${process.env.REACT_APP_BASE_URL}/admin/user/edit/${id}`, basicPayload, {
      headers: {
        'x-token': `${loggedAdmin}`
      }
    }).then((res) => {
      setSuccess(true)
      setOnprocess(false)
    }).catch((err) => {
      setOnprocess(false)
      setError(err.response.data.message)
      setTimeout(() => {
        setError('')
      }, 4000)
    })
  }

  useEffect(() => {
    try {
      if (dob.length > 0) {
        setDob(`${dob.split('T')[0]}`)
      }
    } catch {}
  }, [dob])

  const profileInputFields = [
    {
      name: 'First Name',
      type: 'text',
      inputType: 'input',
      value: firstName,
      setAction: (e: any) => { setFirstName(e.target.value) },
      disabled: false
    },
    {
      name: 'Middle Name',
      type: 'text',
      inputType: 'input',
      value: lastName,
      setAction: (e: any) => { setLastName(e.target.value) },
      disabled: false
    },
    {
      name: 'Last Name',
      type: 'text',
      inputType: 'input',
      value: surname,
      setAction: (e: any) => { setSurname(e.target.value) },
      disabled: false
    },
    {
      name: 'Username',
      type: 'text',
      inputType: 'input',
      value: username,
      setAction: (e: any) => { setUsername(e.target.value) },
      disabled: false
    },
    {
      name: 'Date of Birth',
      type: 'date',
      inputType: 'input',
      value: dob,
      setAction: (e: any) => { setDob(e.target.value) },
      disabled: true
    },
    {
      name: 'Gender',
      type: '',
      inputType: 'select',
      miniArray: [
        { name: 'Male' },
        { name: 'Female' }
      ],
      value: gender,
      setAction: (e: any) => { setGender(e.target.value) },
      disabled: false
    },
    {
      name: 'BVN',
      type: 'number',
      inputType: 'input',
      value: bvn,
      setAction: () => {},
      disabled: true
    },
    {
      name: 'Email Address',
      type: 'email',
      inputType: 'input',
      value: email,
      setAction: () => {},
      disabled: true
    },
    {
      name: 'Phone Number',
      type: 'text',
      inputType: 'input',
      value: phone,
      setAction: (e: any) => { setPhone(e.target.value) },
      disabled: false
    },
    {
      name: 'Address',
      type: 'text',
      inputType: 'input',
      value: address,
      setAction: (e: any) => { setAddress(e.target.value) },
      disabled: false
    }
  ]

  return (
        <>
            {
                success
                  ? <Alert
                        closeFunc={() => { setSuccess(false) }}
                        img='/icons/success.png'
                        message='Modified Successfully'
                        miniMessage="You have successfully modified this user's information"
                    />
                  : null
            }
            {
                 error
                   ? <Alert
                        closeFunc={() => { setError('') }}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                   : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='User'
                            redirectUrl='/users'
                        />
                        <DescHeader>
                            <h3>User</h3>
                        </DescHeader>
                        <TableWrap
                            style={{
                              padding: '0.5rem 15px 0 15px',
                              borderRadius: '30px 30px 0 0'
                            }}
                        >
                            <HeroCover></HeroCover>
                            <TableFlex>
                                <SmallTableSpace>
                                    <h3
                                        style={{
                                          color: '#ffab01'
                                        }}
                                    >Profile</h3>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/account-information/${id}`}>
                                        <h3>Account Information</h3>
                                    </Link>
                                </DateWrap>
                                <DateWrap>
                                    <Link to={`/profile/transactions/${id}`}>
                                        <h3>Transactions</h3>
                                    </Link>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/kyc/${id}`}>
                                        <h3>KYC</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/complain-log/${id}`}>
                                        <h3>Complain Log</h3>
                                    </Link>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/audit-trail/${id}`}>
                                        <h3>Audit</h3>
                                    </Link>
                                </SmallTableSpace>
                            </TableFlex>
                        </TableWrap>
                        {
                            userInfo && Object.keys(userInfo).length > 0
                              ? <UserCard>
                                <div>
                                    <div>

                                        <p>User Id</p>
                                        <h3>{userInfo.uid}</h3>
                                    </div>
                                    <div>
                                        <BasicFlex>
                                            <IconCard background='#E0E3EB' color='#122466'>
                                                <Icon.Call />
                                            </IconCard>
                                            <div>
                                                <GreyText>PHONE</GreyText>
                                                <h3>{userInfo.phone}</h3>
                                            </div>
                                        </BasicFlex>
                                    </div>
                                    <GridTexts>
                                        <p>BVN</p>
                                        <h3>{bvn || 'Not Verified'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Account Number</p>
                                        <h3
                                            style={{
                                              fontSize: '13px'
                                            }}
                                        >{`${account ? account.accountNumber : ''} | ${account ? account.bankName : ''}`}</h3>
                                    </GridTexts>
                                </div>
                                <Line />
                                <div>
                                    <UserProfileCard>
                                        {
                                            userInfo.image
                                              ? <AvatarImage
                                                    src={userInfo.image}
                                                    alt='Profile'
                                                />
                                              : <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                      fontWeight: 800
                                                    }}
                                                >
                                                    {userInfo.firstName ? userInfo.firstName[0].toUpperCase() : ''}
                                                </IconCard>
                                        }
                                                <div>
                                                    <span>User</span>
                                                    <h3>{`${userInfo.firstName} ${userInfo.surname}`}</h3>
                                                    <p>{userInfo.email}</p>
                                                </div>
                                    </UserProfileCard>
                                    <GridTexts>
                                        <p>Created</p>
                                        <h3>{moment(userInfo.createdAt).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Last Updated KYC</p>
                                        <h3>{userInfo.idDoc === 0 ? 'Not Updated' : moment(userInfo.user_updatedAt).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Status</p>
                                        <h3
                                            style={{
                                              color: '#ffab01'
                                            }}
                                        >{getKycStatus(userInfo.idDoc)}</h3>
                                    </GridTexts>
                                    <BalanceCard>
                                        <IconCard background='#4DC736' color='#fff'>
                                            &#8358;
                                        </IconCard>
                                        <GridTexts>
                                            <p>Balance</p>
                                            <h3>&#8358;{CommaNumber(userInfo ? userInfo.walletBalance : '')}</h3>
                                        </GridTexts>
                                    </BalanceCard>
                                </div>
                                <SpaceWrap>
                                    {
                                        profileInputFields.map((item, index) => (
                                            <ThreeSpaceGridWrap
                                                key={index}
                                            >
                                                <div>
                                                    <h4>{item.name}</h4>
                                                </div>
                                                <div>
                                                    <InputWrap>
                                                        <InputField>
                                                            <legend>{item.name}</legend>
                                                            {
                                                                item.inputType === 'input'
                                                                  ? <input
                                                                        type={item.type}
                                                                        placeholder={item.name}
                                                                        autoComplete="off"
                                                                        value={item.value}
                                                                        onChange={item.setAction}
                                                                        disabled={item.disabled}
                                                                    />
                                                                  : <select
                                                                        value={item.value}
                                                                        onChange={item.setAction}
                                                                        disabled={item.disabled}
                                                                    >
                                                                        {item.miniArray?.map((item: any, index: number) => (
                                                                            <option key={index}>{item.name}</option>
                                                                        ))}
                                                                    </select>
                                                            }
                                                        </InputField>
                                                    </InputWrap>
                                                </div>
                                                <div></div>
                                            </ThreeSpaceGridWrap>
                                        ))
                                    }
                                    <FlexedBtn
                                        style={{
                                          margin: '3rem 0 0 0',
                                          gap: '5px'
                                        }}
                                    >
                                        <button
                                            onClick={() => { EditUser() }}
                                        >
                                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Update'}
                                        </button>
                                        <button
                                            style={{
                                              border: '1px solid #ffab01',
                                              color: '#ffab01',
                                              background: 'transparent'
                                            }}
                                        >
                                            Reset
                                        </button>
                                    </FlexedBtn>
                                </SpaceWrap>
                            </UserCard>
                              : <Loader />
                        }
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default ProfileIndex
