import React from 'react'
import { GraphCard, MainGraphWrap, SmallGraphGrid } from '../../styles/dashboard/graphStyle'
// import EmptyState from '../reusable/emptyState';

interface PropsArgs {
  props: any
}
const SmallGraphSection = ({ props }: PropsArgs) => {
  const graphData = [
    {
      header: 'Total Users',
      value: props.Total_user ? props.Total_user : 0,
      img: '/images/chart1.png'
    },
    {
      header: 'Total of Active Users',
      value: props.TotalActive ? props.TotalActive : 0,
      img: '/images/chart2.png'
    },
    {
      header: 'Total of Inactive Users',
      value: props.TotalInactive ? props.TotalInactive : 0,
      img: '/images/chart2.png'
    },
    {
      header: 'Total New Users this month',
      value: '0',
      img: '/images/chart2.png'
    }
  ]

  return (
        <>
            <MainGraphWrap>
                <SmallGraphGrid>
                    {
                        graphData.map((item, index) => (
                            <GraphCard
                                key={index}
                            >
                                <p>{item.header}</p>
                                <h3>{item.value}</h3>
                                {/* <img
                                    src={item.img}
                                    alt='Graph'
                                /> */}
                            </GraphCard>
                        ))
                    }
                </SmallGraphGrid>
            </MainGraphWrap>
        </>
  )
}

export default SmallGraphSection
