import React, { useState, useEffect, useRef } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, Role, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import axios from 'axios'
import Loader from '../reusable/loader'
import EmptyState from '../reusable/emptyState'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { AvatarImage } from '../reusable/style'
import BreadcrumbArea from '../reusable/breadcrumb'
import CsvDownloader from 'react-csv-downloader'
import PaginationComp from '../reusable/pagination'
import moment from 'moment'
import RangeModal from '../reusable/rangeModal'

const UsersMain = () => {
  const date = new Date()
  const navigate = useNavigate()
  const exportButton: any = useRef(null)

  // States
  const [users, setUsers] = useState<any>('')
  const [usersMutable, setMutableUsers] = useState<any>([])
  const [searchedItem, setSearchedItem] = useState('')
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [openExport, setOpenExport] = useState(false)
  const [exportData, setExportData] = useState([])
  const [exportProcess, setExportProcess] = useState(false)

  // Export Range

  const loggedAdmin = localStorage.getItem('tks') || '{}'

  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  const getRangeData = (e: any) => {
    e.preventDefault()
    if (startDate && endDate) {
      setExportProcess(true)
      const loggedAdmin = localStorage.getItem('tks') || '{}'

      const body = {
        headers: {
          'Content-Type': 'application/json',
          'x-token': `${loggedAdmin}`
        }
      }

      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/range/users/${new Date(startDate).getTime()}/${new Date(endDate).getTime()}`, body)
        .then(res => {
          setExportProcess(false)
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement)
          }
        }).catch(err => {
          setExportProcess(false)
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/search/users?limit=20&page=${page}&key=${searchedItem}`, body)
        .then(res => {
          setUsers(res.data.data)
          setMutableUsers(res.data.data)
          setTotalData(res.data.data.length)
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click()
      setOpenExport(false)
    }
  }, [exportData])

  // Call Function
  const getUsers = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/users/all?limit=20&page=${page}`, body)
      .then(res => {
        setUsers(res.data.data.data)
        setMutableUsers(res.data.data.data)
        setTotalData(res.data.data.total)
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }
  // Get Users
  useEffect(() => {
    getUsers()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getUsers()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getUsers()
    }
  }

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem('')
    getUsers()
  }

  // Search Algorithm
  const handleSearch: any = (e: any) => {
    const new_item: any = []
    if (users !== '' && users.length !== 0) {
      for (let i = 0; i < users.length; i++) {
        if (
          users[i].firstName && users[i].firstName.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||

                users[i].surname && users[i].surname.toLowerCase()
                  .includes(e.target.value.toLowerCase()) ||

                users[i].phone && users[i].phone
                  .includes(e.target.value)

        ) {
          new_item.push(users[i])
        } else if (e.target.value === '') {
          setMutableUsers(users)
        }
      }
      setMutableUsers(new_item)
    }
  }

  return (
        <>
            {
                openExport
                  ? <RangeModal
                        closeFunc={() => { setOpenExport(false) }}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        action={getRangeData}
                        onProcess={exportProcess}
                    />
                  : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Users'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Users ({totalData})</h3>
                        </DescHeader>
                        <HeaderItems>
                            <DashboardInput
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input
                                    placeholder='Search user name here'
                                    value={searchedItem}
                                    onChange={(e) => {
                                      setSearchedItem(e.target.value)
                                    }}
                                />
                                <i>
                                    <Icon.SearchNormal1
                                        size={18}
                                        onClick={() => { searchItem() }}
                                    />
                                </i>
                            </DashboardInput>
                            <CsvDownloader
                                    datas={exportData}
                                    filename={`Users - ${moment(date)}`}

                                >
                                    <button ref={exportButton} style={{ visibility: 'hidden' }}>

                                    </button>
                                </CsvDownloader>
                                <FlexedBtn
                                    onClick={() => { setOpenExport(true) }}
                                >
                                    <button>
                                        <Icon.DocumentDownload />
                                        Export
                                    </button>
                                </FlexedBtn>
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                                onClick={() => { refreshFilter() }}
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                            {
                                users !== ''
                                  ? usersMutable && usersMutable.length > 0
                                    ? <>
                                        {
                                            usersMutable.map((item: any, index: number) => (
                                                <Link
                                                    key={index}
                                                    to={`/profile/${item.uid}`}
                                                >
                                                    <TableFlex
                                                        key={index}
                                                    >

                                                        <TableName>
                                                            <UserProfileCard>
                                                                {
                                                                    item.image
                                                                      ? <AvatarImage
                                                                            src={item.image}
                                                                            alt='Profile'
                                                                        />
                                                                      : <IconCard
                                                                            background='#FEF9E5'
                                                                            color='#FFAB01'
                                                                            style={{
                                                                              fontWeight: 800
                                                                            }}
                                                                        >
                                                                            {item.firstName && item.firstName[0].toUpperCase()}
                                                                        </IconCard>
                                                                }
                                                                <div>
                                                                    <h3>
                                                                        {`${item.firstName ? item.firstName : ''} 
                                                                        ${item.surname ? item.surname : ''}`}
                                                                        {
                                                                            !item.firstName && !item.surname
                                                                              ? 'No Name'
                                                                              : null
                                                                        }
                                                                    </h3>
                                                                    <p>{item.email && item.email}</p>
                                                                </div>
                                                            </UserProfileCard>
                                                        </TableName>
                                                        <Role style={{ width: '25%' }}>
                                                            <p>Location</p>
                                                            <h3>{item.address ? item.address : 'No Address'}</h3>
                                                        </Role>
                                                        <DateWrap>
                                                            <p>Phone Number</p>
                                                            <h3>{item.phone}</h3>
                                                        </DateWrap>
                                                        <SmallTableSpace>
                                                            <StatusCard
                                                                bg='#DBFFDA'
                                                                color='#68E365'
                                                            >
                                                                {item.blocked ? 'Disabled' : 'Enabled'}
                                                            </StatusCard>
                                                        </SmallTableSpace>
                                                        <Icon.Edit2
                                                            color='#32D16D'
                                                            size={18}
                                                        />
                                                    </TableFlex>
                                                </Link>
                                            ))
                                        }
                                        <PaginationComp
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default UsersMain
