import React, { useState } from 'react'
import {
  MainWrap,
  AuthFlex,
  AuthLeft,
  AuthLogoTop,
  AuthText,
  AuthRight,
  AuthImage,
  AuthLogoImage,
  InputField,
  BlueText,
  PinFlex,
  AuthImageTop
  , InputWrap
} from '../../styles/authentication'
import { Button } from '../../styles/reusable'
import * as Icon from 'react-feather'
import { useParams, useNavigate } from 'react-router'
import OtpInput from 'react-otp-input'
import axios from 'axios'
import Error from '../reusable/error'
import { ClipLoader } from 'react-spinners'

const ConfirmEmail = () => {
  const width = window.innerWidth
  const navigate = useNavigate()
  const { email } = useParams()
  const inputStyle: any = {
    width: width > 728 ? '2.5rem' : '2.1rem',
    height: width > 728 ? '2.5rem' : '2.1rem',
    background: 'transparent',
    border: '1px solid #E3E3E3',
    borderRadius: '4px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginRight: width > 728 ? 15 : 15,
    color: 'black',
  }

  // States
  const [emailCode, setEmailCode] = useState('')
  const [error, setError] = useState('')
  const [onProcess, setOnProcess] = useState(false)

  // Functions

  const basicPayload = {
    email,
    code: emailCode
  }

  const getDevice = () => {
    if (navigator.userAgent.match(/Android/i) != null) {
      return 'Android'
    }
    if (navigator.userAgent.match(/webOS/i) != null) {
      return 'Web OS'
    }
    if (navigator.userAgent.match(/iPhone/i) != null) {
      return 'Iphone'
    }
    if (navigator.userAgent.match(/iPad/i) != null) {
      return 'iPad'
    }
    if (navigator.userAgent.match(/iPod/i) != null) {
      return 'iPod'
    }
    if (navigator.userAgent.match(/BlackBerry/i) != null) {
      return 'BlackBerry'
    }
    if (navigator.userAgent.match(/Windows Phone/i) != null) {
      return 'Windows Phones'
    }
  }

  const auditPayload: any = {
    message: 'Login',
    device: getDevice()
  }

  const handleVerify = (e: any) => {
    e.preventDefault()
    setOnProcess(true)
    if (emailCode && emailCode.length === 6) {
      axios.post(`${process.env.REACT_APP_BASE_URL}/auth/admins/verify`, basicPayload)
        .then(res => {
          setOnProcess(false)
          localStorage.setItem('loggedAdmin', JSON.stringify(res.data.data.details))
          localStorage.setItem('tks', res.data.data.details.token)
          navigate('/')
          // axios.post(`${process.env.REACT_APP_BASE_URL}/admin/audit`, auditPayload, {
          //     headers: {
          //         "x-token": `${res.data.data.details.token}`
          //     }})
          //     .then(res => {
          //         setOnProcess(false);
          //         navigate('/');
          //     }).catch(err => {
          //         setOnProcess(false);
          //         setError(err.response.data.message);
          //         console.log(err.response.data.message)
          //         setTimeout(() => {
          //             setError('');
          //         }, 4000)
          //     })
        }).catch(err => {
          setOnProcess(false)
          setError(err.response.data.message)
          setTimeout(() => {
            setError('')
          }, 4000)
        })
    } else {
      setOnProcess(false)
      setError('Email Code is not complete.')
      setTimeout(() => {
        setError('')
      }, 4000)
    }
  }

  // @ts-ignore
    return (
        <>
            <MainWrap>
                <AuthFlex>
                    <AuthLeft>
                        <h3>
                            Best solutions & <br />
                            value-for your business
                        </h3>
                        <AuthImage
                            src='/images/auth.png'
                            alt='Mongoro'
                        />
                        <AuthLogoImage
                            src='/mongoro-white.png'
                            alt='Mongoro'
                        />
                    </AuthLeft>
                    <AuthRight onSubmit={(e) => { handleVerify(e) }}>
                        <AuthLogoTop
                            src='/mongoro.png'
                            alt='Mongoro'
                        />
                        <div>
                            <AuthImageTop
                                src='/icons/key.png'
                                alt='Auth Icon'
                            />
                            <h3>Authenticate your account</h3>
                            {/* <AuthText>
                                You’re trying to sign in:  <br />
                                <span>
                                    <Icon.AlertCircle
                                        size={14}
                                        style={{
                                            position:'relative',
                                            top:'0.2rem',
                                            margin: '0 0.1rem'
                                        }}
                                    /> {email}
                                </span>
                            </AuthText> */}
                            <AuthText>
                                Protecting your activities is our priority.
                                Please confirm your account by entering the authentication code sent
                                to <b>{email}</b>
                            </AuthText>
                            {
                                error !== ''
                                  ? <Error
                                        message={error}
                                    />
                                  : null
                            }
                            <PinFlex>
                                <div>
                                    <OtpInput
                                        value={emailCode}
                                        onChange={(code: string) => { setEmailCode(code) }}
                                        numInputs={6}
                                        inputStyle={inputStyle}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        isInputSecure={false}
                                    />
                                </div>
                            </PinFlex>
                            <InputWrap>
                            <Button
                                    bg='var(--primary-color)'
                                    color='#fff'
                                    type='submit'
                                    disabled={onProcess}
                                    style={
                                        onProcess ? { cursor: 'not-allowed' } : { cursor: 'pointer' }
                                    }
                                >
                                    {onProcess
                                      ? <ClipLoader
                                            color='#fff'
                                            size={17}
                                        />
                                      : 'Verify'
                                    }
                                </Button>
                                {/* <BlueText>Get Help</BlueText> */}
                            </InputWrap>
                        </div>
                    </AuthRight>
                </AuthFlex>
            </MainWrap>
        </>
  )
}

export default ConfirmEmail
