import React from 'react'
import SingleUser from '../components/singleUser/index'

const SingleUserPage = () => {
  return (
        <>
            <SingleUser />
        </>
  )
}

export default SingleUserPage
