import React from 'react'
import SettingsMain from '../components/settings'
import ControlPanelMain from '../components/settings/control-panel'

const ControlPanelPage = () => {
  return (
        <>
            <ControlPanelMain />
        </>
  )
}

export default ControlPanelPage
