import React from 'react'
import Login from '../components/Login/index'

const LoginPage = () => {
  return (
        <>
            <Login />
        </>
  )
}

export default LoginPage
