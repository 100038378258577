import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, FlexedBtn, UserCard, UserProfileCard, GridTexts, IconCard } from '../../../styles/reusable/index'
import SideBarWidget from '../../reusable/sidebar'
import Header from '../../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import BreadcrumbArea from '../../reusable/breadcrumb'
import { AvatarImage } from '../../reusable/style'
import axios from 'axios'
import { useNavigate } from 'react-router'
import EnterIdModal from './enterId'
import moment from 'moment'
import UpdateIdModal from './update'

const IdentityLookup = () => {
  const navigate = useNavigate()
  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const [fetched, setFetched] = useState(false)
  const [activeLookup, setActiveLookup] = useState('1')
  const [id, setId] = useState('')
  const [showIdModal, setShowIdModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [userInformation, setUserInformation] = useState<any>({})

  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Bvn/Identity Lookup'
                            redirectUrl='/account'
                        />
                        <DescHeader>
                            <h3>BVN/ID Lookup</h3>
                        </DescHeader>
                        <InputField
                            style={{
                              width: '15rem'
                            }}
                        >
                            <legend>Select Action</legend>
                            <select
                                required
                                defaultValue={'Select Action'}
                                onChange={(e) => {
                                  setActiveLookup(e.target.value)
                                  setFetched(false)
                                  setUserInformation({})
                                }}
                            >
                                {
                                    lookupArray.map((item, index) => (
                                        <option key={index} value={index + 1}>{item.name} Lookup</option>
                                    ))
                                }
                            </select>
                        </InputField>
                        {
                            fetched && userInformation && Object.keys(userInformation).length > 0
                              ? null
                              : <FlexedBtn
                                    style={{
                                      margin: '2rem 0 0 0',
                                      gap: '5px'
                                    }}
                                >
                                    <button
                                        onClick={() => { setShowIdModal(true) }}
                                    >
                                        Continue
                                    </button>
                                </FlexedBtn>
                        }

                        {
                            fetched && userInformation && Object.keys(userInformation).length > 0
                              ? <UserCard>
                                <i
                                    style={{
                                      position: 'absolute',
                                      top: '1rem',
                                      right: '1rem',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      setFetched(false)
                                      setUserInformation({})
                                    }}
                                >
                                    <FeatherIcon.X color='#c82b32' />
                                </i>
                                <div>
                                    <UserProfileCard>
                                        {
                                            userInformation.image
                                              ? <AvatarImage
                                                    src={userInformation.image}
                                                    alt='Profile'
                                                />
                                              : <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                      fontWeight: 800
                                                    }}
                                                >
                                                    {userInformation.firstName ? userInformation.firstName[0].toUpperCase() : ''}
                                                </IconCard>
                                        }
                                                <div>
                                                    <span>User</span>
                                                    <h3>{`${userInformation.firstName} ${userInformation.surname}`}</h3>
                                                </div>
                                    </UserProfileCard>
                                    <GridTexts>
                                        <p>First name</p>
                                        <h3>{userInformation.firstName ? userInformation.firstName : 'Not Set'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Middle name</p>
                                        <h3>{userInformation.middleName ? userInformation.middleName : 'Not Set'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Last name</p>
                                        <h3>{userInformation.surname ? userInformation.surname : 'Not Set'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Email Address</p>
                                        <h3>{userInformation.email && userInformation.email}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Date of birth</p>
                                        <h3>{userInformation.dob ? moment(userInformation.dob).format('LL') : 'Not Available'}</h3>
                                    </GridTexts>
                                    <br />
                                    <GridTexts>
                                        <p>Address</p>
                                        <h3>{userInformation.address ? userInformation.address : 'Not Set'}</h3>
                                    </GridTexts>
                                </div>
                                <FlexedBtn
                                    style={{
                                      margin: '2rem 0 0 0',
                                      gap: '5px'
                                    }}
                                >
                                    <button
                                        onClick={() => { setShowUpdateModal(true) }}
                                    >
                                        Update Information
                                    </button>
                                </FlexedBtn>
                            </UserCard>
                              : null
                    }
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
            {
                showIdModal
                  ? <EnterIdModal
                        closeFunc={() => { setShowIdModal(false) }}
                        active={activeLookup}
                        setBaseId={setId}
                        setUser={setUserInformation}
                        setFetched={setFetched}
                    />
                  : null
            }
            {
                showUpdateModal
                  ? <UpdateIdModal
                        active={activeLookup}
                        userEmail={userInformation && Object.keys(userInformation).length > 0 ? userInformation.email : ''}
                        userPhone={userInformation && Object.keys(userInformation).length > 0 ? userInformation.phone : ''}
                        userId={userInformation && Object.keys(userInformation).length > 0 ? id : ''}
                        closeFunc={() => { setShowUpdateModal(false) }}
                    />
                  : null
            }
        </>
  )
}

export default IdentityLookup

const lookupArray = [
  {
    name: 'BVN'
  },
  {
    name: 'Identity'
  }
]
