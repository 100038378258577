import React from 'react'
import TicketsMain from '../components/tickets/index'

const TicketsPage = () => {
  return (
        <>
            <TicketsMain />
        </>
  )
}

export default TicketsPage
