export function handleBg (state: string) {
  if (state) {
    if (
      state === 'successful'.toLowerCase() ||
        state === 'success'.toLowerCase() ||
        state === 'active'.toLowerCase() ||
        state === 'Complete'.toLowerCase() ||
        state === 'Completed'.toLowerCase() ||
        state === 'Open' ||
        state === 'Open tickets'
    ) {
      return '#DBFFDA'
    } else if (
      state === 'pending'.toLowerCase() ||
        state.toLowerCase() === 'Archived'.toLowerCase()
    ) {
      return '#F1F1F1'
    } else if (
      state === 'failed'.toLowerCase() ||
        state === 'Closed ticket' ||
        state === 'Closed'
    ) {
      return '#FFEAEA'
    }
  }
}

export function handleColor (state: string) {
  if (state) {
    if (
      state === 'successful'.toLowerCase() ||
          state === 'success'.toLowerCase() ||
          state === 'active'.toLowerCase() ||
          state === 'Complete'.toLowerCase() ||
          state === 'Completed'.toLowerCase() ||
          state === 'Open' ||
          state === 'Open tickets'
    ) {
      return '#32d16d'
    } else if (
      state === 'pending'.toLowerCase() ||
          state.toLowerCase() === 'Archived'.toLowerCase()
    ) {
      return '#474747'
    } else if (
      state === 'failed'.toLowerCase() ||
        state === 'Closed ticket' ||
        state === 'Closed'
    ) {
      return '#F2000B'
    }
  }
}
