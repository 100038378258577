import React from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, BasicFlex, GreyText, UserProfileCard, GridTexts, BalanceCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'

const Access = () => {
  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <DescHeader>
                            <h3>Manage Access/User</h3>
                            <FlexedBtn>
                                <button>
                                    <Icon.TableDocument />
                                    Create Category
                                </button>
                                <button>
                                    <Icon.AddCircle />
                                    Add Admin
                                </button>
                            </FlexedBtn>
                        </DescHeader>
                        <UserCard>
                            <div>
                                <div>
                                    <p>User Id</p>
                                    <h3>#00123521</h3>
                                </div>
                                <div>
                                    <BasicFlex>
                                        <IconCard background='#E0E3EB' color='#122466'>
                                            <Icon.Call />
                                        </IconCard>
                                        <div>
                                            <GreyText>PHONE</GreyText>
                                            <h3>+234 816 441 1188</h3>
                                        </div>
                                    </BasicFlex>
                                </div>
                            </div>
                            <Line />
                            <div>
                                <UserProfileCard>
                                    <img
                                        src='/images/avatar.png'
                                        alt='User'
                                    />
                                    <div>
                                        <span>User</span>
                                        <h3>AdeIfe</h3>
                                        <p>adeife@gmail.com</p>
                                    </div>
                                </UserProfileCard>
                                <GridTexts>
                                    <p>Created</p>
                                    <h3>April 29, 2022</h3>
                                </GridTexts>
                                <GridTexts>
                                    <p>Updated</p>
                                    <h3>April 29, 2022</h3>
                                </GridTexts>
                                <GridTexts>
                                    <p>Status</p>
                                    <h3>Admin</h3>
                                </GridTexts>
                                <BalanceCard>
                                    <IconCard background='#4DC736' color='#fff'>
                                        #
                                    </IconCard>
                                    <GridTexts>
                                        <p>Amount</p>
                                        <h3>&#8358;763,200.00</h3>
                                    </GridTexts>
                                </BalanceCard>
                            </div>
                        </UserCard>
                        <DashboardInput wrapWidth={'40%'}>
                            <label>Name</label>
                            <input
                                placeholder='Super Admin'
                            />
                        </DashboardInput>
                        <TableWrap>
                            <TableFlex>
                                <SmallTableSpace>
                                    <h3>#</h3>
                                </SmallTableSpace>
                                <TableName>
                                    <h3>CREATE ROLE PERMISSIONS</h3>
                                </TableName>
                                <SmallTableSpace>
                                    <h3>Activate Access</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Deactivate Access</h3>
                                </SmallTableSpace>
                            </TableFlex>
                            <Line />
                            {
                                accessData.map((item, index) => (
                                    <TableFlex
                                        key={index}
                                    >
                                        <SmallTableSpace>
                                            <p>{index + 1}</p>
                                        </SmallTableSpace>
                                        <TableName>
                                            <p>{item.name}</p>
                                        </TableName>
                                        <SmallTableSpace>
                                            <input
                                                type='checkbox'
                                            />
                                        </SmallTableSpace>
                                        <SmallTableSpace>
                                            <input
                                                type='checkbox'
                                            />
                                        </SmallTableSpace>
                                    </TableFlex>
                                ))
                            }

                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default Access

const accessData = [
  {
    name: 'Admin Dashboard'
  },
  {
    name: 'Users'
  },
  {
    name: 'Chats'
  },
  {
    name: 'Services'
  },
  {
    name: 'Transactions'
  },
  {
    name: 'Invoices'
  },
  {
    name: 'Wallet'
  },
  {
    name: 'mPos'
  },
  {
    name: 'Packages'
  },
  {
    name: 'Services providers'
  },
  {
    name: 'Settings'
  },
  {
    name: 'Super Admin'
  }
]
