import React from 'react'
import WithdrawalMain from '../components/withdrawal'

const WithdrawalPage = () => {
  return (
        <>
            <WithdrawalMain />
        </>
  )
}

export default WithdrawalPage
