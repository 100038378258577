import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, HeaderItems, FilterSelect, IconBtn, UserFlex, UserProfileCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import BreadcrumbArea from '../reusable/breadcrumb'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Loader from '../reusable/loader'
import EmptyState from '../reusable/emptyState'
import moment from 'moment'
import CsvDownloader from 'react-csv-downloader'
import { AvatarImage } from '../reusable/style'
import PaginationComp from '../reusable/pagination'

const LoginActivities = () => {
  const date = new Date()
  const navigate = useNavigate()
  const [audits, setAudits] = useState<any>('')
  const [auditsMutable, setMutableAudits] = useState<any>([])
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')

  // Call Function
  const getAudits = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/audit/all?limit=20&page=${page}`, body)
      .then(res => {
        setAudits(res.data.results)
        setMutableAudits(res.data.results)
        setTotalData(res.data.TotalResult)
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  // Get Audits
  useEffect(() => {
    getAudits()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getAudits()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getAudits()
    }
  }

  // Search Algorithm
  const handleSearch: any = (e: any) => {
    const new_item: any = []
    if (audits !== '' && audits.length !== 0) {
      for (let i = 0; i < audits.length; i++) {
        if (
          audits[i].device
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||

                audits[i].message
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())

        ) {
          new_item.push(audits[i])
        } else if (e.target.value === '') {
          setMutableAudits(audits)
        }
      }
      setMutableAudits(new_item)
    }
  }

  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Audit Trail'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Audit Trail ({auditsMutable.length})</h3>
                        </DescHeader>
                        <HeaderItems>
                            <FilterSelect>
                                <option>Status</option>
                            </FilterSelect>
                            <FilterSelect>
                                <option>Filter</option>
                            </FilterSelect>
                            <DashboardInput
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input
                                    placeholder='Search Audits detail'
                                    onChange={(e) => handleSearch(e)}
                                />
                                <i><Icon.SearchNormal1 size={18} /></i>
                            </DashboardInput>
                            <CsvDownloader
                                datas={audits}
                                filename={`Audits - ${moment(date)}`}
                            >
                            <FlexedBtn>
                                <button>
                                    <Icon.DocumentDownload />
                                    Export
                                </button>
                            </FlexedBtn>
                            </CsvDownloader>
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                            <TableFlex>
                                <DateWrap>
                                    <h3>Date and Time</h3>
                                </DateWrap>
                                <TableName>
                                    <h3>User</h3>
                                </TableName>
                                <SmallTableSpace>
                                    <h3>Device</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Message</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>IP</h3>
                                </SmallTableSpace>
                            </TableFlex>
                            <Line />
                            {
                                audits !== ''
                                  ? auditsMutable && auditsMutable.length > 0
                                    ? <>
                                        {
                                            auditsMutable.map((item: any, index: number) => (
                                    <TableFlex
                                        key={index}
                                    >
                                        <DateWrap>
                                            <p>{moment(item.login_at).format('LLL')}</p>
                                        </DateWrap>
                                        <TableName>
                                            {
                                                item.email
                                                  ? <div>
                                                            <p>{item.email}</p>
                                                        </div>
                                                  : <p>No Admin Information</p>
                                            }
                                        </TableName>
                                        <SmallTableSpace>
                                            <p>{item.device}</p>
                                        </SmallTableSpace>
                                        <SmallTableSpace>
                                            <p>{item.message}</p>
                                        </SmallTableSpace>
                                        <SmallTableSpace>
                                            <p>{item.ip ? item.ip : ''}</p>
                                        </SmallTableSpace>
                                    </TableFlex>
                                            ))
                            }
                            <PaginationComp
                                page={page}
                                setPage={setPage}
                                limit={20}
                                total={totalData}
                                incrementAction={incrementAction}
                                decrementAction={decrementAction}
                            />
                        </>
                                    : <EmptyState />
                                  : <Loader />
                }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default LoginActivities
