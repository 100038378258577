import React, { useEffect, useState } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import CardSection from './cardSection'
import SmallGraphSection from './smallGraphSection'
import OtherCardSection from './otherCards'
import * as Icon from 'iconsax-react'
import axios from 'axios'
import { useNavigate } from 'react-router'

const Overview = () => {
  const navigate = useNavigate()
  const loggedAdmin = JSON.parse(localStorage.getItem('loggedAdmin') || '{}')

  // States and setState
  const [allInformation, setAllInformation] = useState<any>({})
  const [user, setUser] = useState({})
  const [wallet, setWallet] = useState('')
  const [walletBalance, setWalletBalance] = useState('')
  const [walletUpdatedItem, setWalletUpdatedTime] = useState('')

  useEffect(() => {
    if (loggedAdmin) {
      setUser(loggedAdmin)
      setWallet('0')
      setWalletBalance('0')
      setWalletUpdatedTime(Date())
    }
  }, [])

  // Get All Informations
  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/total/all`, body)
      .then(res => {
        setAllInformation(res.data.data)
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }, [])

  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <DescHeader>
                            <h3>Dashboard</h3>
                            {/* <FlexedBtn>
                                <button>
                                    <Icon.DocumentDownload />
                                    Download Report
                                </button>
                            </FlexedBtn> */}
                        </DescHeader>
                        <CardSection
                            props={allInformation}
                            savingsProps={allInformation.saving && Object.keys(allInformation).length > 0 ? allInformation.saving : {}}
                            transactionProps={allInformation.transaction ? allInformation.transaction : {}}
                            depositProps={allInformation.totalDeposit ? allInformation.totalDeposit : {}}
                            withdrawProps={allInformation.totalWithdrawal ? allInformation.totalWithdrawal : {}}
                        />
                        <SmallGraphSection props={allInformation} />
                        <OtherCardSection />
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default Overview
