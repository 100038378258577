import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import store from './redux/store';

import axios from 'axios'

const loggedAdmin = localStorage.getItem('tks') || '{}'

// Request interceptor
axios.interceptors.request.use(
  (config: any) => {
    config.headers = {
      'x-signature': process.env.REACT_APP_SIGNATURE,
      'x-token': `${loggedAdmin}`
    }
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
      <App />
    {/* </Provider> */}

  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
