import React from 'react'
import styled from 'styled-components'
import { Button } from '../../styles/reusable'
import * as Icon from 'iconsax-react'

interface PropsArgs {
  limit: number
  page: number
  setPage: any
  total: number | string | any
  incrementAction: any
  decrementAction: any
}

const PaginationComp = ({ page, limit, total, incrementAction, decrementAction, setPage }: PropsArgs) => {
  const incrementCount = () => {
    if (page < Math.ceil(total / limit)) {
      setPage(++page)
      incrementAction()
    }
  }

  const decrementCount = () => {
    if (page > 1) {
      setPage(--page)
      decrementAction()
    }
  }

  return (
        <>
            <PaginationWrap>
                <div>
                    <p>Showing {
                        Math.ceil(limit * page) >= total
                          ? total
                          : limit * page
                        } of {total}</p>
                </div>
                <div>
                    <Button
                        bg='transparent'
                        color='#A2AD1F'
                        border="0.5px solid #A2AD1F"
                        onClick={() => { decrementCount() }}
                    >
                        <Icon.ArrowLeft />
                        Previous
                    </Button>
                    <Button
                        bg='transparent'
                        color='#A2AD1F'
                        border="0.5px solid #A2AD1F"
                        onClick={() => { incrementCount() }}
                    >
                        Next
                        <Icon.ArrowRight />
                    </Button>
                </div>
            </PaginationWrap>
        </>
  )
}

export default PaginationComp

const PaginationWrap = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:2rem 0 0 0;

    > div {
        flex:1;
    }

    > div:nth-child(2){
        display:flex;
        justify-content:flex-end;
        gap:20px;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #474747;
    }

    @media (max-width:728px){
        flex-direction:column;
    }
`
