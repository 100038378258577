export const getKycStatus = (number: number) => {
  if (number === 0) {
    return 'Not Set'
  } else if (number === 1) {
    return 'Pending'
  } else if (number === 2) {
    return 'Approved'
  } else if (number === 3) {
    return 'Rejected'
  }
}
