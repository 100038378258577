import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, GridPaddedSpace, AccountGrid, AccountCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import BreadcrumbArea from '../reusable/breadcrumb'
import BanksModal from './banksModal'
import { BigCardsGrid, GraphCard } from '../../styles/dashboard/graphStyle'
import Gateway from './subBusiness/gateway'
import POSPayIn from './subBusiness/posPayIn'
import POSPayOut from './subBusiness/posPayOut'
import Collection from './subBusiness/collection'
import Disbursement from './subBusiness/disbursement'
import axios from 'axios'
import { useNavigate } from 'react-router'

const BusinessModel = () => {
  const navigate = useNavigate()
  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const [clickCount, setClickCount] = useState(0)
  const [bankState, setBankState] = useState('Activate')
  const [activeItem, setActiveItem] = useState(-1)
  const [showBankModal, setShowBankModal] = useState(false)
  const [allBusinessModel, setAllBusinessModal] = useState({})
  const [vendors, setVendors] = useState([])
  const [activeProvider, setActiveProvider] = useState('')
  const [mode, setMode] = useState('view')
  const [active, setActive] = useState('')
  const [activeId, setActiveId] = useState<any>('')
  const [fetching, setFetching] = useState(false)
  const [gatewayModel, setGatewayModel] = useState<any>({})
  const [posPayInModel, setPosPayInModel] = useState<any>({})
  const [posPayOutModel, setPosPayOutModel] = useState<any>({})
  const [collectionModel, setCollectionModel] = useState<any>({})
  const [disbursementModel, setDisbursementModel] = useState<any>({})

  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  const getBusinessModel = () => {
    setFetching(true)
    if (activeProvider) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/business/provider/${activeId}`, body)
        .then(res => {
          setFetching(false)
          setAllBusinessModal(res.data.data)
          setGatewayModel(res.data.data.Gateway[0])
          setPosPayInModel(res.data.data.posPayIn)
          setPosPayOutModel(res.data.data.posPayOut)
          setCollectionModel(res.data.data.Collection)
          setDisbursementModel(res.data.data.Disbursement)
        }).catch(err => {
          setFetching(false)
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  const getAllProviders = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/business/service/providers`, body)
      .then(res => {
        setVendors(res.data.data)
        if (res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].active) {
              setActiveProvider(res.data.data[i].vendorName)
              setActiveId(res.data.data[i].prid)
            }
          }
        }
      }).catch(err => {
        setFetching(false)
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  useEffect(() => {
    getAllProviders()
  }, [])

  useEffect(() => {
    getBusinessModel()
  }, [activeProvider])

  useEffect(() => {
    if (clickCount > 0) {
      setShowBankModal(true)
    }
  }, [clickCount, bankState])

  return (
        <>
            {
                showBankModal
                  ? <BanksModal
                        closeFunc={() => { setShowBankModal(false) }}
                        mode={bankState}
                    />
                  : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Business Model'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Business Model</h3>
                        </DescHeader>

                        {/* Cards */}

                        <Gateway
                            mode={mode}
                            active={active}
                            setActive={setActive}
                            setMode={setMode}
                            model={gatewayModel}
                            vendors={vendors}
                            activeVendor={activeProvider}
                        />
                        <POSPayIn
                            mode={mode}
                            active={active}
                            setActive={setActive}
                            setMode={setMode}
                            model={posPayInModel}
                            vendors={vendors}
                            activeVendor={activeProvider}
                        />
                        <POSPayOut
                            mode={mode}
                            active={active}
                            setActive={setActive}
                            setMode={setMode}
                            model={posPayOutModel}
                            vendors={vendors}
                            activeVendor={activeProvider}
                        />
                        <Collection
                            mode={mode}
                            active={active}
                            setActive={setActive}
                            setMode={setMode}
                            model={collectionModel}
                            vendors={vendors}
                            activeVendor={activeProvider}
                        />
                        <Disbursement
                            mode={mode}
                            active={active}
                            setActive={setActive}
                            setMode={setMode}
                            model={disbursementModel}
                            vendors={vendors}
                            activeVendor={activeProvider}
                        />
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default BusinessModel
