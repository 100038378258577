import React from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import Typography from '../reusable/typography'
import { CheckBoxFlex, GenericHeader, GenericHeaderCard, SettingsGrid, SettingsMainWrap } from '../../styles/settings'
import { Link } from 'react-router-dom'

const ControlPanelMain = () => {
  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <DescHeader>
                            <h3>Control Panel</h3>
                        </DescHeader>
                        <SettingsMainWrap>
                            <GenericHeader>
                                <Link to='/settings'>
                                    <GenericHeaderCard
                                        background='transparent'
                                        color='#8d9096'
                                    >
                                        General Settings
                                    </GenericHeaderCard>
                                </Link>
                                <GenericHeaderCard
                                    background='#fff'
                                    color='#ffab01'
                                >
                                    Control Panel
                                </GenericHeaderCard>
                            </GenericHeader>
                            {
                                SettingItem.map((item, index) => (
                                    <SettingsGrid
                                        key={index}
                                    >
                                        <div>
                                            <Typography
                                                text={item.title}
                                                fontSize='15px'
                                                color='#202020'
                                                fontWeight={700}
                                            />
                                            <Typography
                                                text={item.text}
                                                fontSize='13px'
                                                color='#202020'
                                            />
                                        </div>
                                        <CheckBoxFlex>
                                            <input type='checkbox' />
                                            <Typography
                                                text={'Activate'}
                                                fontSize='13px'
                                                color='#5D5B5B'
                                            />
                                        </CheckBoxFlex>
                                        <CheckBoxFlex>
                                            <input type='checkbox' />
                                            <Typography
                                                text={'Deactivate'}
                                                fontSize='13px'
                                                color='#5D5B5B'
                                            />
                                        </CheckBoxFlex>
                                    </SettingsGrid>
                                ))
                            }
                            <FlexedBtn
                                style={{
                                  margin: '3rem 0 0 2%'
                                }}
                            >
                                <button>
                                    Save Changes
                                </button>
                            </FlexedBtn>
                        </SettingsMainWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default ControlPanelMain

const SettingItem = [
  {
    title: 'Transfer Account Fund',
    text: 'Shows a forms field of Old Phone Number, New Phone Number, Your PIN, Submit        '
  },
  {
    title: 'Update BVN',
    text: 'Shows a form field of BVN, New Phone Number, Your PIN, Submit'
  },
  {
    title: 'Verify BVN',
    text: 'Shows a form field of Phone Number, BVN, Date of Birth, PIN, Submit'
  },
  {
    title: 'Update/Verify Account No',
    text: 'Shows a forms field of Phone Number, Bank Account Number, PIN, Submit'
  },
  {
    title: 'Fund Account',
    text: 'Shows a form field of Phone Number, Amount, Purpose, Your PIN, Submit'
  },
  {
    title: 'Update Access',
    text: 'Shows a form field of Phone Number, Options (Unset Phone Number, Unset BVN, Block User, Unblock User, Clear Main Wallet)'
  },
  {
    title: 'Debit Account',
    text: 'Shows a form field of Phone Number, Amount, Purpose, Your PIN, Submit'
  },
  {
    title: 'Disbursement Provider',
    text: 'Shows a forms field of Switch Vendor, Drop down box, PIN, Submit'
  }
]
