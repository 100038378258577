import React, { useState, useEffect, useRef } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, Role, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, Line, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import moment from 'moment'
import EmptyState from '../reusable/emptyState'
import Loader from '../reusable/loader'
import CsvDownloader from 'react-csv-downloader'
import BreadcrumbArea from '../reusable/breadcrumb'
import PaginationComp from '../reusable/pagination'
import RangeModal from '../reusable/rangeModal'
import axios from 'axios'
import { useNavigate } from 'react-router'

const MPosMain = () => {
  const navigate = useNavigate()
  const date = new Date()
  const exportButton: any = useRef(null)

  // States

  const [searchedItem, setSearchedItem] = useState('')
  const [mpos, setMpos] = useState<any>('')
  const [mposMutable, setMutableMpos] = useState<any>([])
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [openExport, setOpenExport] = useState(false)
  const [exportData, setExportData] = useState([])
  const [exportProcess, setExportProcess] = useState(false)

  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  // Export Range
  const getRangeData = (e: any) => {
    e.preventDefault()
    if (startDate && endDate) {
      setExportProcess(true)

      const body = {
        headers: {
          'Content-Type': 'application/json',
          'x-token': `${loggedAdmin}`
        }
      }

      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/range/mpos/${new Date(startDate).getTime()}/${new Date(endDate).getTime()}`, body)
        .then(res => {
          setExportProcess(false)
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement)
          }
        }).catch(err => {
          setExportProcess(false)
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/search/mpos?limit=20&page=${page}&key=${searchedItem}`, body)
        .then(res => {
          setMpos(res.data.data)
          setMutableMpos(res.data.data)
          setTotalData(res.data.data.length)
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click()
      setOpenExport(false)
    }
  }, [exportData])

  // Call Function
  const getPos = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/admin/mpos/all?limit=20&page=${page}`, body)
      .then(res => {
        res.json().then((resp) => {
          setMpos(resp.data)
          setMutableMpos(resp.data)
          setTotalData(resp.data.length)
        })
      }).catch(err => {
      })
  }

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem('')
    getPos()
  }

  // Get POS
  useEffect(() => {
    getPos()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getPos()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getPos()
    }
  }

  return (
        <>
            {
                openExport
                  ? <RangeModal
                        closeFunc={() => { setOpenExport(false) }}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        action={getRangeData}
                        onProcess={exportProcess}
                    />
                  : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='POS'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>POS ({mposMutable.length})</h3>
                        </DescHeader>
                        <HeaderItems>
                            <DashboardInput
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input
                                    placeholder='Search user name here'
                                    value={searchedItem}
                                    onChange={(e) => {
                                      setSearchedItem(e.target.value)
                                    }}
                                />
                                <i>
                                    <Icon.SearchNormal1
                                        size={18}
                                        onClick={() => { searchItem() }}
                                    />
                                </i>
                            </DashboardInput>
                            <CsvDownloader
                                datas={exportData}
                                filename={`POS - ${moment(date)}`}

                            >
                                <button ref={exportButton} style={{ display: 'none' }}>

                                </button>
                            </CsvDownloader>
                            <FlexedBtn
                                onClick={() => { setOpenExport(true) }}
                            >
                                <button>
                                    <Icon.DocumentDownload />
                                    Export
                                </button>
                            </FlexedBtn>
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                                onClick={() => { refreshFilter() }}
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                            <TableFlex>
                                <DateWrap>
                                    <h3>Date</h3>
                                </DateWrap>
                                <Role>
                                    <h3>Owner</h3>
                                </Role>
                                <Role>
                                    <h3>Type</h3>
                                </Role>
                                {/* <SmallTableSpace>
                                    <h3>Deposit Fee</h3>
                                </SmallTableSpace> */}
                                <SmallTableSpace>
                                    <h3>Quantity</h3>
                                </SmallTableSpace>
                                <DateWrap>
                                    <h3>Location</h3>
                                </DateWrap>
                            </TableFlex>
                            <Line />
                            {
                                mpos !== ''
                                  ? mposMutable && mposMutable.length > 0
                                    ? <>
                                        {
                                            mposMutable.map((item: any, index: number) => (
                                                <TableFlex
                                                    key={index}
                                                >
                                                    <DateWrap>
                                                        <p>{moment(item.createdAt).format('LL')}</p>
                                                    </DateWrap>
                                                    <Role>
                                                        <p>{item.businessName}</p>
                                                    </Role>
                                                    <Role>
                                                        <p>{item.type}</p>
                                                    </Role>
                                                    {/* <SmallTableSpace>
                                                        <p
                                                            style={{
                                                                color: '#4DC736'
                                                            }}
                                                        >&#8358;5,000</p>
                                                    </SmallTableSpace> */}
                                                    <SmallTableSpace>
                                                        <p>{item.quantity}</p>
                                                    </SmallTableSpace>
                                                    <DateWrap>
                                                        <p>
                                                            {item.state
                                                              ? item.state.replace(
                                                                item.state[0], item.state[0].toUpperCase()
                                                              )
                                                              : ''
                                                            }

                                                            {item.country
                                                              ? ` ,${item.country.replace(item.country[0], item.country[0].toUpperCase())}`
                                                              : ''
                                                            }
                                                        </p>
                                                    </DateWrap>
                                                </TableFlex>
                                            ))
                                        }
                                        <PaginationComp
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default MPosMain
