import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, FlexedBtn, UserCard, UserProfileCard, GridTexts, IconCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import BreadcrumbArea from '../reusable/breadcrumb'
import { AvatarImage } from '../reusable/style'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Alert from '../reusable/alert'
import { BeatLoader, ClipLoader } from 'react-spinners'

const FundAccount = () => {
  const navigate = useNavigate()
  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const [fetching, setFetching] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [activeLookup, setActiveLookup] = useState('1')
  const [id, setId] = useState('')
  const [showIdModal, setShowIdModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [username, setUsername] = useState('')
  const [amount, setAmount] = useState('')
  const [narration, setNarration] = useState('')
  const [userInformation, setUserInformation] = useState<any>({})
  const [onProcess, setOnProcess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  const getUserByName = () => {
    if (username.length > 0) {
      setFetching(true)
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/walletTag/${username}`, body)
        .then(res => {
          setFetching(false)
          setFetched(true)
          setUserInformation(res.data.data)
        }).catch(err => {
          setFetching(false)
          setError(err.response.data.message)
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  const payload = {
    amount,
    username
  }

  const fundAction = (e: any) => {
    e.preventDefault()
    setOnProcess(true)
    axios.post(`${process.env.REACT_APP_BASE_URL}/admin/panel/account/fund`, payload, {
      headers: {
        'x-token': `${loggedAdmin}`
      }
    }).then((res) => {
      setOnProcess(false)
      setSuccess(true)
    }).catch((err) => {
      setOnProcess(false)
      setError(err.response.data.message)
      setTimeout(() => {
        setError('')
      }, 4000)
    })
  }

  return (
        <>
            {
                success
                  ? <Alert
                        closeFunc={() => { setSuccess(false) }}
                        img='/icons/success.png'
                        message='Funding successful!!!'
                        miniMessage={`You have successfully credited @${username} the sum of ₦${amount}`}
                    />
                  : null
            }
            {
                 error
                   ? <Alert
                        closeFunc={() => { setError('') }}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                   : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <form onSubmit={(e) => { fundAction(e) }}>
                        <BreadcrumbArea
                            navName='Fund Account'
                            redirectUrl='/account'
                        />
                        <DescHeader>
                            <h3>Fund Account</h3>
                        </DescHeader>
                        <InputField
                            style={{
                              width: '15rem'
                            }}
                        >
                            <legend>Enter Username</legend>
                            <input
                                type='text'
                                placeholder='username'
                                onChange={(e) => { setUsername(e.target.value) }}
                                onBlur={() => { getUserByName() }}
                                required
                            />
                        </InputField>
                        <InputField
                            style={{
                              width: '15rem'
                            }}
                        >
                            <legend>Amount</legend>
                            <input
                                type='number'
                                placeholder='Amount'
                                onChange={(e) => { setAmount(e.target.value) }}
                                required
                            />
                        </InputField>
                        <InputField
                            style={{
                              width: '15rem'
                            }}
                        >
                            <legend>Narration</legend>
                            <input
                                type='text'
                                placeholder='Narration'
                                required
                            />
                        </InputField>
                        {
                            fetched && userInformation && Object.keys(userInformation).length > 0
                              ? <UserCard
                                style={{
                                  margin: '3rem 0 0 0'
                                }}
                            >
                                <i
                                    style={{
                                      position: 'absolute',
                                      top: '1rem',
                                      right: '1rem',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      setFetched(false)
                                      setUserInformation({})
                                    }}
                                >
                                    <FeatherIcon.X color='#c82b32' />
                                </i>
                                <div>
                                    <UserProfileCard>
                                        {
                                            userInformation.image
                                              ? <AvatarImage
                                                    src={userInformation.image}
                                                    alt='Profile'
                                                />
                                              : <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                      fontWeight: 800
                                                    }}
                                                >
                                                    {userInformation.firstName ? userInformation.firstName[0].toUpperCase() : ''}
                                                </IconCard>
                                        }
                                                <div>
                                                    <span>User</span>
                                                    <h3>{`${userInformation.firstName} ${userInformation.surname}`}</h3>
                                                </div>
                                    </UserProfileCard>
                                    <GridTexts>
                                        <p>First name</p>
                                        <h3>{userInformation.firstName ? userInformation.firstName : 'Not Set'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Middle name</p>
                                        <h3>{userInformation.middleName ? userInformation.middleName : 'Not Set'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Last name</p>
                                        <h3>{userInformation.surname ? userInformation.surname : 'Not Set'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Email Address</p>
                                        <h3>{userInformation.email && userInformation.email}</h3>
                                    </GridTexts>
                                    <br />
                                    <GridTexts>
                                        <p>Address</p>
                                        <h3>{userInformation.address ? userInformation.address : 'Not Set'}</h3>
                                    </GridTexts>
                                </div>
                                {
                                    fetched && userInformation && Object.keys(userInformation).length > 0
                                      ? <FlexedBtn
                                            style={{
                                              margin: '2rem 0 0 0',
                                              gap: '5px'
                                            }}
                                        >
                                            <button
                                                type='submit'
                                                disabled={onProcess}
                                                style={
                                                    onProcess
                                                      ? {
                                                          cursor: 'not-allowed'
                                                        }
                                                      : {}
                                                }
                                            >
                                                {onProcess ? <ClipLoader /> : 'Fund Account'}
                                            </button>
                                        </FlexedBtn>
                                      : null
                                }
                            </UserCard>
                              : null
                    }
                    </form>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default FundAccount

const lookupArray = [
  {
    name: 'BVN'
  },
  {
    name: 'Identity'
  }
]
