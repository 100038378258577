import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import axios from 'axios'
import Loader from '../reusable/loader'
import EmptyState from '../reusable/emptyState'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { AvatarImage } from '../reusable/style'
import BreadcrumbArea from '../reusable/breadcrumb'
import moment from 'moment'
import { getKycStatus } from '../../utils/getKycStatus'
import PaginationComp from '../reusable/pagination'

const UsersMain = () => {
  const date = new Date()
  const navigate = useNavigate()
  // States
  const [users, setUsers] = useState('')
  const [usersMutable, setMutableUsers] = useState<any>([])
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')
  const [searchedItem, setSearchedItem] = useState('')

  const loggedAdmin = localStorage.getItem('tks') || '{}'

  const body = {
    headers: {
      'Content-Type': 'application/json',
      'x-token': `${loggedAdmin}`
    }
  }

  // Call Function
  const getUsers = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/users/all?limit=20&page=${page}`, body)
      .then(res => {
        setUsers(res.data.data.data)
        setMutableUsers(res.data.data.data)
        setTotalData(res.data.data.total)
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  // Search Endpoint
  const searchItem = () => {
    if (searchedItem) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/search/users?limit=20&page=${page}&key=${searchedItem}`, body)
        .then(res => {
          setUsers(res.data.data)
          setMutableUsers(res.data.data)
          setTotalData(res.data.data.length)
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  // Get Users
  useEffect(() => {
    getUsers()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getUsers()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getUsers()
    }
  }

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem('')
    getUsers()
  }

  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='KYC'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>KYC</h3>
                        </DescHeader>
                        <HeaderItems>
                            <DashboardInput
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input
                                    placeholder='Search user name here'
                                    value={searchedItem}
                                    onChange={(e) => {
                                      setSearchedItem(e.target.value)
                                    }}
                                />
                                <i><Icon.SearchNormal1 size={18} onClick={() => { searchItem() }} /></i>
                            </DashboardInput>
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                                onClick={() => { refreshFilter() }}
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                        <TableFlex>
                                <SmallTableSpace>
                                    <h3>Date</h3>
                                </SmallTableSpace>
                                <DateWrap>
                                    <h3>User</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>KYC Type</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <h3>Tier</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Status</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Action</h3>
                                </SmallTableSpace>
                            </TableFlex>
                            <Line />
                            {
                                users !== ''
                                  ? usersMutable && usersMutable.length > 0
                                    ? <>
                                        {
                                            usersMutable.map((item: any, index: number) => (
                                                <Link
                                                    key={index}
                                                    to={`/profile/kyc/${item.uid}`}
                                                >
                                                    <TableFlex
                                                        key={index}
                                                    >
                                                        <SmallTableSpace>
                                                            <p>{moment(date).format('LL')}</p>
                                                        </SmallTableSpace>
                                                        <DateWrap>
                                                            <UserProfileCard>
                                                                <div>
                                                                    <span
                                                                        style={{
                                                                          color: '#161616',
                                                                          fontSize: '12px'
                                                                        }}
                                                                    >{item.firstName} {item.surname}</span>
                                                                </div>
                                                            </UserProfileCard>
                                                        </DateWrap>
                                                        <DateWrap>
                                                            <p>No information</p>
                                                        </DateWrap>
                                                        <SmallTableSpace>
                                                            <p>{item.tiers}</p>
                                                        </SmallTableSpace>
                                                        <SmallTableSpace>
                                                            <p
                                                                style={{
                                                                  color: 'rgba(26, 79, 131, 0.75)'
                                                                }}
                                                            >{getKycStatus(item.idDoc)}</p>
                                                        </SmallTableSpace>
                                                        <SmallTableSpace>
                                                            <p
                                                                style={{
                                                                  color: '#FFAB01'
                                                                }}
                                                            >View</p>
                                                        </SmallTableSpace>
                                                    </TableFlex>
                                                </Link>
                                            ))
                                        }
                                        <PaginationComp
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default UsersMain
