import React, { useState, useEffect } from 'react'
import { StatusCard, DashboardMainFlex, DashboardMainBody, BalanceCard, AppMainBody, DescHeader, FlexedBtn, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, Role, DateWrap, MenuSpace, UserCard, IconCard, BasicFlex, GreyText, UserProfileCard, GridTexts, ThreeSpaceGridWrap, SpaceWrap, HeroCover } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router'
import axios from 'axios'
import Loader from '../reusable/loader'
import { Link } from 'react-router-dom'
import CommaNumber from 'comma-number'
import EmptyState from '../reusable/emptyState'
import { AvatarImage } from '../reusable/style'
import BreadcrumbArea from '../reusable/breadcrumb'
import { handleBg, handleColor } from '../../utils/colorHandle'
import { getKycStatus } from '../../utils/getKycStatus'

const UserTransactionComp = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  // States
  const [userInfo, setUserInfo] = useState<any>({})
  const [account, setAccount] = useState<any>({})
  const [bvn, setBvn] = useState('')

  // States
  const [transactions, setTransactions] = useState<any>('')
  const [transactionsMutable, setMutableTransactions] = useState<any>([])

  // Get Transactions
  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transaction/user/${id}?limit=5&page=1`, body)
      .then(res => {
        setTransactions(res.data.data.data)
        setMutableTransactions(res.data.data.data)
      }).catch(err => {
        if (err.response.data.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }, [])

  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'
    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    if (id) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/${id}`, body)
        .then(res => {
          setUserInfo(res.data.data)
          setAccount(res.data.data.Accounts[0])
          setBvn(res.data.data.Bvn[0].data.idNumber)
        }).catch(err => {})
    }
  }, [id])

  //

  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='User'
                            redirectUrl='/users'
                        />
                        <DescHeader>
                            <h3>User</h3>
                        </DescHeader>
                        <TableWrap
                            style={{
                              padding: '0.5rem 15px 0 15px',
                              borderRadius: '30px 30px 0 0'
                            }}
                        >
                            <HeroCover></HeroCover>
                            <TableFlex>
                                <SmallTableSpace>
                                    <Link to={`/profile/${id}`}>
                                        <h3>Profile</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/account-information/${id}`}>
                                        <h3>Account Information</h3>
                                    </Link>
                                </DateWrap>
                                <DateWrap>
                                    <h3
                                        style={{
                                          color: '#ffab01'
                                        }}
                                    >Transactions</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/kyc/${id}`}>
                                        <h3>KYC</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/complain-log/${id}`}>
                                        <h3>Complain Log</h3>
                                    </Link>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/audit-trail/${id}`}>
                                        <h3>Audit</h3>
                                    </Link>
                                </SmallTableSpace>
                            </TableFlex>
                        </TableWrap>
                        {
                            userInfo && Object.keys(userInfo).length > 0
                              ? <>
                            <UserCard>
                                <div>
                                    <div>
                                        <p>User Id</p>
                                        <h3>{userInfo.uid}</h3>
                                    </div>
                                    <div>
                                        <BasicFlex>
                                            <IconCard background='#E0E3EB' color='#122466'>
                                                <Icon.Call />
                                            </IconCard>
                                            <div>
                                                <GreyText>PHONE</GreyText>
                                                <h3>{userInfo.phone}</h3>
                                            </div>
                                        </BasicFlex>
                                    </div>
                                    <GridTexts>
                                        <p>BVN</p>
                                        <h3>{bvn || 'Not Verified'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Account Number</p>
                                        <h3
                                            style={{
                                              fontSize: '13px'
                                            }}
                                        >{`${account && account.accountNumber ? account.accountNumber : ''} | ${account && account.bankName ? account.bankName : ''}`}</h3>
                                    </GridTexts>
                                </div>
                                <Line />
                                <div>
                                    <UserProfileCard>
                                    {
                                            userInfo.image
                                              ? <AvatarImage
                                                    src={userInfo.image}
                                                    alt='Profile'
                                                />
                                              : <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                      fontWeight: 800
                                                    }}
                                                >
                                                    {userInfo.firstName ? userInfo.firstName[0].toUpperCase() : ''}
                                                </IconCard>
                                        }
                                        <div>
                                            <span>User</span>
                                            <h3>{`${userInfo.firstName} ${userInfo.surname}`}</h3>
                                            <p>{userInfo.email}</p>
                                        </div>
                                    </UserProfileCard>
                                    <GridTexts>
                                        <p>Created</p>
                                        <h3>{moment(userInfo.createdAt).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Last Updated KYC</p>
                                        <h3>{userInfo.idDoc === 0 ? 'Not Updated' : moment(userInfo.user_updated_at).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Status</p>
                                        <h3
                                            style={{
                                              color: '#ffab01'
                                            }}
                                        >{getKycStatus(userInfo.idDoc)}</h3>
                                    </GridTexts>
                                    <BalanceCard>
                                        <IconCard background='#4DC736' color='#fff'>
                                            &#8358;
                                        </IconCard>
                                        <GridTexts>
                                            <p>Balance</p>
                                            <h3>&#8358;{CommaNumber(userInfo ? userInfo.walletBalance : '')}</h3>
                                        </GridTexts>
                                    </BalanceCard>
                                </div>
                                <SpaceWrap>

                                </SpaceWrap>
                            </UserCard><TableWrap>
                                <TableFlex>
                                    <SmallTableSpace>
                                        <h3>I.D</h3>
                                    </SmallTableSpace>
                                    <SmallTableSpace>
                                        <h3>Date</h3>
                                    </SmallTableSpace>
                                    <DateWrap>
                                        <h3>Narration</h3>
                                    </DateWrap>
                                    <DateWrap>
                                        <h3>Sender Details</h3>
                                    </DateWrap>
                                    <DateWrap>
                                        <h3>Reference</h3>
                                    </DateWrap>
                                    <SmallTableSpace>
                                        <h3>Amount</h3>
                                    </SmallTableSpace>
                                    <SmallTableSpace>
                                        <p>Status</p>
                                    </SmallTableSpace>
                                </TableFlex>
                            <Line />
                            {
                                transactions !== ''
                                  ? transactionsMutable && transactionsMutable.length > 0
                                    ? <>
                                        {
                                            transactionsMutable.map((item: any, index: number) => (
                                                <TableFlex
                                                    key={index}
                                                >
                                                    <SmallTableSpace>
                                                        <h3>{item.transactionID}</h3>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <p>{moment(item.Date).format('LL')}</p>
                                                    </SmallTableSpace>
                                                    <DateWrap>
                                                        <p>{item.narration ? item.narration : 'No Narration'}</p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <UserProfileCard>
                                                            <div>
                                                                <span
                                                                    style={{
                                                                      color: '#161616',
                                                                      fontSize: '12px'
                                                                    }}
                                                                >{item.fullName ? item.fullName : ''}</span>
                                                                <span
                                                                    style={{
                                                                      color: '#3B4CB8',
                                                                      fontSize: '10px',
                                                                      margin: '10px 0 0 0'
                                                                    }}
                                                                >{item.bankName ? item.bankName : ''}</span>
                                                                <h3
                                                                    style={{
                                                                      color: '#161616',
                                                                      fontSize: '10px',
                                                                      fontWeight: '400',
                                                                      lineHeight: '20px'
                                                                    }}
                                                                >
                                                                    {item.accountNumber ? `${item.accountNumber}` : ''}
                                                                </h3>
                                                            </div>
                                                        </UserProfileCard>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <p>{item.reference}</p>
                                                    </DateWrap>
                                                    <SmallTableSpace>
                                                        <p
                                                            style={
                                                                item.statusType === 'Debit' || item.serviceType === 'Transfer'
                                                                  ? {
                                                                      color: '#F42F4B'
                                                                    }
                                                                  : {
                                                                      color: '#4DC736'
                                                                    }
                                                            }
                                                        >
                                                            {item.statusType === 'Debit' || item.serviceType === 'Transfer' ? '-' : ''}&#8358;
                                                            {CommaNumber(item.amount)}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <StatusCard
                                                            bg={`${handleBg(item.status)}`}
                                                            color={`${handleColor(item.status)}`}
                                                        >
                                                            {item.status}
                                                        </StatusCard>
                                                    </SmallTableSpace>
                                                </TableFlex>
                                            ))
                                        }
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                        </>
                              : <Loader />
                        }
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default UserTransactionComp
