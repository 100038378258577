import React from 'react'
import UsersMain from '../components/users'

const Users = () => {
  return (
        <>
            <UsersMain />
        </>
  )
}

export default Users
