import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, InputField, FlexedBtn, UserCard, UserProfileCard, GridTexts, IconCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import BreadcrumbArea from '../reusable/breadcrumb'
import { AvatarImage } from '../reusable/style'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Alert from '../reusable/alert'
import { BeatLoader, ClipLoader } from 'react-spinners'

const ResetInformation = () => {
  const navigate = useNavigate()
  const loggedAdmin = localStorage.getItem('tks') || '{}'
  const [activeLookup, setActiveLookup] = useState<string | number>(1)
  const [userValue, setUserValue] = useState('')
  const [onProcess, setOnProcess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')

  // Get Text Display
  const getText = () => {
    switch (activeLookup) {
      case 1:
        return 'NIN'
      case 2:
        return 'Phone Number'
      case 3:
        return 'BVN'
      case 4:
        return 'Email Address'
      default:
        break
    }
  }

  // Get Text Display
  const getInputType = () => {
    switch (activeLookup) {
      case 1:
        return 'number'
      case 2:
        return 'number'
      case 3:
        return 'number'
      case 4:
        return 'email'
      default:
        break
    }
  }

  const payload: any = {
    type: `${activeLookup}`
  }

  const fundAction = (e: any) => {
    e.preventDefault()
    if (activeLookup === 1) {
      payload['email'] = userValue
    } else if (activeLookup === 2) {
      payload['email'] = userValue
    } else if (activeLookup === 3) {
      payload['email'] = userValue
    } else if (activeLookup === 4) {
      payload['email'] = userValue
    }

    setOnProcess(true)
    axios.post(`${process.env.REACT_APP_BASE_URL}/admin/panel/reset`, payload, {
      headers: {
        'x-token': `${loggedAdmin}`
      }
    }).then((res) => {
      setOnProcess(false)
      setSuccess(true)
    }).catch((err) => {
      setOnProcess(false)
      setError(err.response.data.message)
      setTimeout(() => {
        setError('')
      }, 4000)
    })
  }

  return (
        <>
            {
                success
                  ? <Alert
                        closeFunc={() => { setSuccess(false) }}
                        img='/icons/success.png'
                        message='Reset successful!!!'
                        miniMessage={`You have successfully reset this user's ${getText()}.`}
                    />
                  : null
            }
            {
                 error
                   ? <Alert
                        closeFunc={() => { setError('') }}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                   : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <form onSubmit={(e) => { fundAction(e) }}>
                        <BreadcrumbArea
                            navName='Account Information'
                            redirectUrl='/account'
                        />
                        <DescHeader>
                            <h3>Account Information</h3>
                        </DescHeader>
                        <InputField
                            style={{
                              width: '15rem'
                            }}
                        >
                            <legend>Select Action</legend>
                            <select
                                required
                                defaultValue={'Select Action'}
                                onChange={(e) => {
                                  setActiveLookup(Number(e.target.value))
                                }}
                            >
                                {
                                    lookupArray.map((item, index) => (
                                        <option key={index} value={index + 1}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </InputField>
                        <InputField
                            style={{
                              width: '15rem'
                            }}
                        >
                            <legend>{getText()}</legend>
                            <input
                                type={`${getInputType()}`}
                                placeholder={`Enter ${getText()}`}
                                onChange={(e) => { setUserValue(e.target.value) }}
                                required
                            />
                        </InputField>
                        <FlexedBtn
                            style={{
                              margin: '2rem 0 0 0',
                              gap: '5px'
                            }}
                            >
                            <button
                                type="submit"
                                disabled={onProcess}
                                style={
                                onProcess
                                  ? {
                                      cursor: 'not-allowed'
                                    }
                                  : {}
                                }
                            >
                                {onProcess ? <ClipLoader /> : 'Reset Information'}
                            </button>
                            </FlexedBtn>
                    </form>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default ResetInformation

const lookupArray = [
  {
    name: 'NIN'
  },
  {
    name: 'Phone Number'
  },
  {
    name: 'BVN'
  },
  {
    name: 'Email'
  }
]
