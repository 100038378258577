import React from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import { ChatMainFlex } from '../../styles/chat'
import MessagesComponent from './messages'
import MainMessageDisplay from './main'
import BreadcrumbArea from '../reusable/breadcrumb'

const Messages = () => {
  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Messaging'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Messaging</h3>
                        </DescHeader>
                        <ChatMainFlex>
                            <MessagesComponent />
                            <MainMessageDisplay />
                        </ChatMainFlex>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default Messages
