import React from 'react'
import { TypographyStyle } from '../../styles/reusable/index'

interface TypoProps {
  text: string
  fontSize?: string
  fontWeight?: number
  color?: string
  lineHeight?: string
}

const Typography = ({ text, color, fontSize, fontWeight, lineHeight }: TypoProps) => {
  return (
        <TypographyStyle
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            lineHeight={lineHeight}
        >
            {text}
        </TypographyStyle>
  )
}

export default Typography
