import React, { useState } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, Role, DateWrap, MenuSpace } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import UsersList from './usersModal'
import InviteUser from './invite'

const UserAccess = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openInvite, setOpenInvite] = useState(false)

  return (
        <>
            {
                openModal
                  ? <UsersList
                        closeFunc={() => { setOpenModal(false) }}
                        category=''
                    />

                  : null
            }
            {
                openInvite
                  ? <InviteUser
                        closeFunc={() => { setOpenInvite(false) }}
                    />
                  : null
            }

            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <DescHeader>
                            <h3>Manage Access/User</h3>
                            <FlexedBtn>
                                <button>
                                    <Icon.TableDocument />
                                    Create Category
                                </button>
                                <button
                                    onClick={() => { setOpenInvite(true) }}
                                >
                                    <Icon.AddCircle />
                                    Add Admin
                                </button>
                            </FlexedBtn>
                        </DescHeader>
                        <DashboardInput
                            showSearch={true}
                            wrapWidth={'40%'}
                        >
                            <input
                                placeholder='Search by user role here'
                            />
                            <i><Icon.SearchNormal1 size={18} /></i>
                        </DashboardInput>
                        <TableWrap>
                            <TableFlex>
                                <SmallTableSpace>
                                    <h3>#</h3>
                                </SmallTableSpace>
                                <Role>
                                    <h3>Role</h3>
                                </Role>
                                <DateWrap>
                                    <h3>Created</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Updated</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <h3>Number of users</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Action</h3>
                                </SmallTableSpace>
                                <MenuSpace></MenuSpace>
                            </TableFlex>
                            <Line />
                            {
                                accessData.map((item, index) => (
                                    <TableFlex
                                        key={index}
                                        onClick={() => { setOpenModal(true) }}
                                        useCursor={true}
                                    >
                                        <SmallTableSpace>
                                            <p>{index + 1}</p>
                                        </SmallTableSpace>
                                        <Role>
                                            <p>{item.name}</p>
                                        </Role>
                                        <DateWrap>
                                            <p>June 1, 2020, 08:22 AM</p>
                                        </DateWrap>
                                        <DateWrap>
                                            <p>June 1, 2020, 08:22 AM</p>
                                        </DateWrap>
                                        <SmallTableSpace>
                                            <p>5</p>
                                        </SmallTableSpace>
                                        <SmallTableSpace>
                                            <Icon.Edit2
                                                size={15}
                                                color='#4DC736'
                                                strokeWidth={1}
                                            />
                                        </SmallTableSpace>
                                        <MenuSpace>
                                            <FeatherIcon.MoreVertical
                                                size={15}
                                                strokeWidth={1}
                                            />
                                        </MenuSpace>
                                    </TableFlex>
                                ))
                            }

                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default UserAccess

const accessData = [
  {
    name: 'Super Admin'
  },
  {
    name: 'Admin'
  },
  {
    name: 'Merchants'
  },
  {
    name: 'Customer Support'
  }
]
