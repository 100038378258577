import React, { useState, useEffect } from 'react'
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import Typography from '../reusable/typography'
import { CheckBoxFlex, GenericHeader, GenericHeaderCard, SettingsGrid, SettingsMainWrap } from '../../styles/settings'
import { Link } from 'react-router-dom'
import BreadcrumbArea from '../reusable/breadcrumb'
import axios from 'axios'
import { useNavigate } from 'react-router'
import Alert from '../reusable/alert'
import { ClipLoader } from 'react-spinners'
import Loader from '../reusable/loader'

const SettingsMain = () => {
  const navigate = useNavigate()
  const loggedAdmin: any = localStorage.getItem('tks') || '{}'
  const [currentSettings, setCurrentSettings] = useState<any>({})
  const [transferMode, setTransferMode] = useState<boolean>()
  const [accessMode, setAccessMode] = useState<boolean>()
  const [onProcess, setOnprocess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [fetching, setFetching] = useState(false)

  const [SettingItem, setSettingItem] = useState([
    {
      title: 'Kill Transfers',
      text: 'Kill Transfer restrict all transfer from the system',
      enabled: transferMode,
      disabled: !transferMode
    },
    {
      title: 'Kill Access',
      text: 'Kill Access restrict all user from Login',
      enabled: accessMode,
      disabled: !accessMode
    }
  ])

  console.log(transferMode)
  // Get Current Settings
  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'
    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }
    setFetching(true)
    axios.get(`${process.env.REACT_APP_BASE_URL}/super/admin/global/all`, body)
      .then(res => {
        setFetching(false)
        setCurrentSettings(res.data.data)
        setTransferMode(res.data.data[0].disableAllTransfer)
        setAccessMode(res.data.data[0].disableAllUsers)
        SettingItem[0].enabled = res.data.data[0].disableAllTransfer
        SettingItem[1].enabled = res.data.data[0].disableAllUsers
        if (res.data.data[0].disableAllTransfer) {
          SettingItem[0].disabled = !res.data.data[0].disableAllTransfer
        }
        if (res.data.data[0].disableAllUsers) {
          SettingItem[1].disabled = !res.data.data[0].disableAllUsers
        }
        setSettingItem([...SettingItem])
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }, [])

  // Change Access Mode
  const enableMode = (index: number) => {
    SettingItem[index].enabled = true
    SettingItem[index].disabled = false
    setSettingItem([...SettingItem])
  }

  const disableMode = (index: number) => {
    SettingItem[index].disabled = true
    SettingItem[index].enabled = false
    setAccessMode(true)
    setSettingItem([...SettingItem])
  }

  // Send Mode to Endpoint
  const basicPayload = {
    by: loggedAdmin.email
  }

  const actionFunction = (url: string) => {
    axios.put(`${process.env.REACT_APP_BASE_URL}/super/admin/global/${url}`, basicPayload, {
      headers: {
        'x-token': `${loggedAdmin}`
      }
    }).then((res) => {
      setSuccess(true)
      setOnprocess(false)
    }).catch((err) => {
      setOnprocess(false)
      setError(err.response.data.message)
      setTimeout(() => {
        setError('')
      }, 4000)
    })
  }

  const editModes = () => {
    setOnprocess(true)
    if (!SettingItem[0].enabled) {
      actionFunction('enable/transaction')
    } else if (SettingItem[0].enabled) {
      actionFunction('disable/transaction')
    }
    if (!SettingItem[1].enabled) {
      actionFunction('enable/user')
    } else if (SettingItem[1].enabled) {
      actionFunction('disable/user')
    }
  }

  return (
        <>
            {
                success
                  ? <Alert
                        closeFunc={() => { setSuccess(false) }}
                        img='/icons/success.png'
                        message='Access modfied!'
                        miniMessage={'You have successfully modified Mongoro\'s Access'}
                    />
                  : null
            }
            {
                 error
                   ? <Alert
                        closeFunc={() => { setError('') }}
                        img='/icons/error.png'
                        message='An error occured!'
                        miniMessage={error}
                    />
                   : null
            }
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Settings'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Settings</h3>
                        </DescHeader>
                        <SettingsMainWrap>
                            <GenericHeader>
                                <GenericHeaderCard
                                    background='#fff'
                                    color='#ffab01'
                                >
                                    General Settings
                                </GenericHeaderCard>
                                {/* <Link to='/control-panel'>
                                    <GenericHeaderCard
                                        background='transparent'
                                        color='#8D9096'
                                    >
                                        Control Panel
                                    </GenericHeaderCard>
                                </Link> */}
                            </GenericHeader>
                            {
                                fetching
                                  ? <Loader
                                        text='Fetching Access Data...'
                                    />
                                  : <>
                                {
                                    SettingItem.map((item: any, index: number) => (
                                        <SettingsGrid
                                            key={index}
                                        >
                                            <div>
                                                <Typography
                                                    text={item.title}
                                                    fontSize='15px'
                                                    color='#202020'
                                                    fontWeight={700}
                                                />
                                                <Typography
                                                    text={item.text}
                                                    fontSize='13px'
                                                    color='#202020'
                                                />
                                            </div>
                                            <CheckBoxFlex>
                                                <input
                                                    type='checkbox'
                                                    checked={item.enabled}
                                                    onChange={() => { enableMode(index) }}
                                                />
                                                <Typography
                                                    text={'Enable'}
                                                    fontSize='13px'
                                                    color='#5D5B5B'
                                                />
                                            </CheckBoxFlex>
                                            <CheckBoxFlex>
                                                <input
                                                    type='checkbox'
                                                    checked={item.disabled}
                                                    onChange={() => { disableMode(index) }}
                                                />
                                                <Typography
                                                    text={'Disable'}
                                                    fontSize='13px'
                                                    color='#5D5B5B'
                                                />
                                            </CheckBoxFlex>
                                        </SettingsGrid>
                                    ))
                                }
                                </>
                            }
                            {
                                !fetching
                                  ? <FlexedBtn
                                        style={{
                                          margin: '3rem 0 0 2%'
                                        }}
                                    >
                                        <button
                                            onClick={() => { editModes() }}
                                        >
                                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Save Changes'}
                                        </button>
                                    </FlexedBtn>
                                  : null
                            }
                        </SettingsMainWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default SettingsMain
