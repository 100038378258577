import React, { useState, useEffect, useRef } from 'react'
import { MenuPopUp, DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, Line, IconCard, HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import Typography from '../reusable/typography'
import { CardGridWrap, GridCard } from '../../styles/tickets'
import axios from 'axios'
import EmptyState from '../reusable/emptyState'
import Loader from '../reusable/loader'
import moment from 'moment'
import { useNavigate } from 'react-router'
import CsvDownloader from 'react-csv-downloader'
import BreadcrumbArea from '../reusable/breadcrumb'
import PaginationComp from '../reusable/pagination'
import RangeModal from '../reusable/rangeModal'

const WithdrawalMain = () => {
  const navigate = useNavigate()
  const date: any = new Date()
  const exportButton: any = useRef(null)

  // States
  const [activeItem, setActiveItem] = useState(-1)
  const [withdrawal, setWithdrawal] = useState<any>('')
  const [withdrawalMutable, setMutableWithdrawal] = useState<any>([])
  const [manualVerified, setManualVerified] = useState<any>([])
  const [issues, setIssues] = useState<any>([])
  const [searchedItem, setSearchedItem] = useState('')
  let [page, setPage] = useState(1)
  const [totalData, setTotalData] = useState<string | number | any>('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [openExport, setOpenExport] = useState(false)
  const [exportData, setExportData] = useState([])
  const [onProcess, setOnProcess] = useState(false)

  // Export Range
  const getRangeData = (e: any) => {
    e.preventDefault()
    if (startDate && endDate) {
      setOnProcess(true)
      const loggedAdmin = localStorage.getItem('tks') || '{}'

      const body = {
        headers: {
          'Content-Type': 'application/json',
          'x-token': `${loggedAdmin}`
        }
      }

      axios.get(`${process.env.REACT_APP_BASE_URL}/range/withdraw/${new Date(startDate).getTime()}/${new Date(endDate).getTime()}`, body)
        .then(res => {
          setOnProcess(false)
          if (res.data.statement.length > 0) {
            setExportData(res.data.statement)
          }
        }).catch(err => {
          setOnProcess(false)
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  useEffect(() => {
    if (exportData.length > 0) {
      exportButton.current.click()
      setOpenExport(false)
    }
  }, [exportData])

  // Get Withdrawal

  // Call Function
  const getWithdrawals = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transaction/withdraw/all?limit=20&page=${page}`, body)
      .then(res => {
        if (res.data.data.data.length > 0) {
          setWithdrawal(res.data.data.data)
          setMutableWithdrawal(res.data.data.data)
          setTotalData(res.data.data.total)
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  // Get Manual
  const getManualWithdrawals = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transaction/withdraw/manual/all?limit=20&page=${page}`, body)
      .then(res => {
        if (res.data.data.data.length > 0) {
          setManualVerified(res.data.data.data)
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  // Get Withdrawals with issues
  const getIssuesWith = () => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transaction/withdraw/issue/all?limit=20&page=${page}`, body)
      .then(res => {
        if (res.data.data.data.length > 0) {
          setIssues(res.data.data.data)
        }
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  useEffect(() => {
    getWithdrawals()
  }, [])

  useEffect(() => {
    getManualWithdrawals()
  }, [])

  useEffect(() => {
    getIssuesWith()
  }, [])

  const incrementAction = () => {
    if (page < Math.ceil(totalData / 20)) {
      setPage(++page)
      getWithdrawals()
    }
  }

  const decrementAction = () => {
    if (page > 1) {
      setPage(--page)
      getWithdrawals()
    }
  }

  // Refresh Filters
  const refreshFilter = () => {
    setSearchedItem('')
    setMutableWithdrawal(withdrawal)
  }

  // Search Algorithm
  const handleSearch: any = (e: any) => {
    const new_item: any = []
    if (withdrawal !== '' && withdrawal.length !== 0) {
      for (let i = 0; i < withdrawal.length; i++) {
        if (
          withdrawal[i].full_name && withdrawal[i].full_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||

                withdrawal[i].bank_name && withdrawal[i].bank_name
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) ||

                withdrawal[i].reference && withdrawal[i].reference
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) ||

                withdrawal[i].account_number && withdrawal[i].account_number
                  .toLowerCase()
                  .includes(e.target.value) ||

                withdrawal[i].amount
                  .toLowerCase()
                  .includes(e.target.value)

        ) {
          new_item.push(withdrawal[i])
        } else if (e.target.value === '') {
          setMutableWithdrawal(withdrawal)
        }
      }
      setMutableWithdrawal(new_item)
    }
  }

  // Other Variables
  const cardsItem = [
    {
      name: 'Total Withdrawal',
      value: totalData,
      iconBg: '#F0CA43',
      iconColor: '',
      main: true,
      icon: Icon.Ticket2
    },
    {
      name: 'Automatic Withdrawal',
      value: totalData - manualVerified.length,
      iconBg: '#007EFF',
      iconColor: '#fff',
      main: false,
      icon: FeatherIcon.Check
    },
    {
      name: 'Manually Verified Withdrawal',
      value: manualVerified.length,
      iconBg: '#A2AD1F',
      iconColor: '#fff',
      main: false,
      icon: FeatherIcon.Clock
    },
    {
      name: 'Withdrawal with issues',
      value: issues.length,
      iconBg: '#F04343',
      iconColor: '#fff',
      main: false,
      icon: FeatherIcon.X
    }
  ]

  return (
        <>
        {
            openExport
              ? <RangeModal
                    closeFunc={() => { setOpenExport(false) }}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    action={getRangeData}
                    onProcess={onProcess}
                />
              : null
        }
            <DashboardMainFlex onClick={() => { setActiveItem(-1) }}>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='Withdrawal'
                            redirectUrl='/'
                        />
                        <DescHeader>
                            <h3>Withdrawals ({totalData})</h3>
                        </DescHeader>
                        <CardGridWrap>
                            {
                                cardsItem.map((item, index) => (
                                    <GridCard
                                        key={index}
                                        background={item.main ? '#FFAB01' : ''}
                                        color={item.main ? '#fff' : ''}
                                    >
                                        <div>
                                            <Typography
                                                text={item.name}
                                                fontSize='12px'
                                                fontWeight={400}
                                                color={item.main ? '#fff' : ''}
                                            />
                                            <Typography
                                                text={item.value}
                                                fontSize='20px'
                                                fontWeight={700}
                                                color={item.main ? '#fff' : ''}
                                            />
                                        </div>
                                        <IconCard
                                            background={item.iconBg}
                                            color={item.iconColor}
                                        >
                                            {
                                                React.createElement(
                                                  item.icon
                                                )
                                            }
                                        </IconCard>
                                    </GridCard>
                                ))
                            }
                        </CardGridWrap>
                        <HeaderItems>
                            <FilterSelect>
                                <option>Filter</option>
                            </FilterSelect>
                            <DashboardInput
                                wrapWidth={'40%'}
                                showSearch={true}
                                transform='none'
                            >
                                <input
                                    placeholder='Search withdrawals here'
                                    onChange={(e) => {
                                      handleSearch(e)
                                      setSearchedItem(e.target.value)
                                    }}
                                    value={searchedItem}
                                />
                                <i><Icon.SearchNormal1 size={18} /></i>
                            </DashboardInput>
                                <CsvDownloader
                                    datas={exportData}
                                    filename={`Withdrawal - ${moment(date)}`}

                                >
                                    <button ref={exportButton} style={{ visibility: 'hidden' }}>

                                    </button>
                                </CsvDownloader>
                                <FlexedBtn
                                    onClick={() => { setOpenExport(true) }}
                                >
                                    <button>
                                        <Icon.DocumentDownload />
                                        Export
                                    </button>
                                </FlexedBtn>
                            <IconBtn
                                background='#4DC736'
                                color='#fff'
                                onClick={() => { refreshFilter() }}
                            >
                                <Icon.ArrowRotateLeft color='#fff' />
                            </IconBtn>
                        </HeaderItems>
                        <TableWrap>
                            <TableFlex>
                                <DateWrap>
                                    <h3>Date</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Name</h3>
                                </DateWrap>
                                <DateWrap>
                                    <h3>Details</h3>
                                </DateWrap>
                                <SmallTableSpace>
                                    <h3>Reference</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Amount</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Status</h3>
                                </SmallTableSpace>
                            </TableFlex>
                            <Line />
                            {
                                withdrawal !== ''
                                  ? withdrawalMutable && withdrawalMutable.length > 0
                                    ? <>
                                        {
                                            withdrawalMutable.map((item: any, index: number) => (
                                                <TableFlex
                                                    key={index}
                                                >
                                                    <DateWrap>
                                                        <p>{moment(item.Date).format('LLL')}</p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <p>
                                                            {item.fullName
                                                              ? item.fullName.replace(
                                                                item.fullName[0], item.fullName[0].toUpperCase()
                                                              )
                                                              : ''
                                                            }
                                                        </p>
                                                    </DateWrap>
                                                    <DateWrap>
                                                        <UserProfileCard>
                                                            <div>
                                                                <span
                                                                    style={{
                                                                      color: '#3B4CB8',
                                                                      fontSize: '10px'
                                                                    }}
                                                                >{item.bankName}</span>
                                                                <h3>{item.accountNumber}</h3>
                                                            </div>
                                                        </UserProfileCard>
                                                    </DateWrap>
                                                    <SmallTableSpace>
                                                        <p>{item.reference}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <p
                                                            style={{
                                                              color: '#4DC736'
                                                            }}
                                                        >&#8358;{item.amount}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <StatusCard
                                                            bg='#DBFFDA'
                                                            color='#68E365'
                                                        >
                                                            {item.status}
                                                        </StatusCard>
                                                    </SmallTableSpace>
                                                </TableFlex>
                                            ))
                                        }
                                        <PaginationComp
                                            page={page}
                                            setPage={setPage}
                                            limit={20}
                                            total={totalData}
                                            incrementAction={incrementAction}
                                            decrementAction={decrementAction}
                                        />
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default WithdrawalMain
