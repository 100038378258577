import React, { useState, useEffect } from 'react'
import { StatusCard, DashboardMainFlex, DashboardMainBody, BalanceCard, AppMainBody, DescHeader, FlexedBtn, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, Role, DateWrap, MenuSpace, UserCard, IconCard, BasicFlex, GreyText, UserProfileCard, GridTexts, ThreeSpaceGridWrap, SpaceWrap, HeroCover } from '../../styles/reusable/index'
import SideBarWidget from '../reusable/sidebar'
import Header from '../reusable/header'
import * as Icon from 'iconsax-react'
import * as FeatherIcon from 'react-feather'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router'
import axios from 'axios'
import Loader from '../reusable/loader'
import { Link } from 'react-router-dom'
import CommaNumber from 'comma-number'
import EmptyState from '../reusable/emptyState'
import { AvatarImage } from '../reusable/style'
import BreadcrumbArea from '../reusable/breadcrumb'
import { handleBg, handleColor } from '../../utils/colorHandle'
import { getKycStatus } from '../../utils/getKycStatus'

const AuditTrailComp = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  // States
  const [userInfo, setUserInfo] = useState<any>({})
  const [bvn, setBvn] = useState('')
  const [account, setAccount] = useState<any>({})

  // States
  const [audits, setAudits] = useState<any>('')
  const [auditsMutable, setMutableAudits] = useState<any>([])

  // Get Audits
  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'

    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/audit/${id}`, body)
      .then(res => {
        setAudits(res.data.audit)
        setMutableAudits(res.data.audit)
      }).catch(err => {
        if (err.response.status === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }, [])

  useEffect(() => {
    const loggedAdmin = localStorage.getItem('tks') || '{}'
    const body = {
      headers: {
        'Content-Type': 'application/json',
        'x-token': `${loggedAdmin}`
      }
    }

    if (id) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/admin/user/${id}`, body)
        .then(res => {
          setUserInfo(res.data.data)
          setAccount(res.data.data.Accounts[0])
          setBvn(res.data.data.Bvn[0].data.idNumber)
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }, [id])

  return (
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <BreadcrumbArea
                            navName='User'
                            redirectUrl='/users'
                        />
                        <DescHeader>
                            <h3>User</h3>
                        </DescHeader>
                        <TableWrap
                            style={{
                              padding: '0.5rem 15px 0 15px',
                              borderRadius: '30px 30px 0 0'
                            }}
                        >
                            <HeroCover></HeroCover>
                            <TableFlex>
                                <SmallTableSpace>
                                    <Link to={`/profile/${id}`}>
                                        <h3>Profile</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/account-information/${id}`}>
                                        <h3>Account Information</h3>
                                    </Link>
                                </DateWrap>
                                <DateWrap>
                                    <Link to={`/profile/transactions/${id}`}>
                                        <h3>Transactions</h3>
                                    </Link>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/kyc/${id}`}>
                                        <h3>KYC</h3>
                                    </Link>
                                </SmallTableSpace>
                                <DateWrap>
                                    <Link to={`/profile/complain-log/${id}`}>
                                        <h3>Complain Log</h3>
                                    </Link>
                                </DateWrap>
                                <SmallTableSpace>
                                    <Link to={`/profile/audit-trail/${id}`}>
                                        <h3
                                            style={{
                                              color: '#ffab01'
                                            }}
                                        >Audit</h3>
                                    </Link>
                                </SmallTableSpace>
                            </TableFlex>
                        </TableWrap>
                        {
                            userInfo && Object.keys(userInfo).length > 0
                              ? <>
                            <UserCard>
                                <div>
                                    <div>
                                        <p>User Id</p>
                                        <h3>{userInfo.uid}</h3>
                                    </div>
                                    <div>
                                        <BasicFlex>
                                            <IconCard background='#E0E3EB' color='#122466'>
                                                <Icon.Call />
                                            </IconCard>
                                            <div>
                                                <GreyText>PHONE</GreyText>
                                                <h3>{userInfo.phone}</h3>
                                            </div>
                                        </BasicFlex>
                                    </div>
                                    <GridTexts>
                                        <p>BVN</p>
                                        <h3>{bvn || 'Not Verified'}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Account Number</p>
                                        <h3
                                            style={{
                                              fontSize: '13px'
                                            }}
                                        >{`${account ? account.accountNumber : ''} | ${account ? account.bankName : ''}`}</h3>
                                    </GridTexts>
                                </div>
                                <Line />
                                <div>
                                    <UserProfileCard>
                                    {
                                            userInfo.image
                                              ? <AvatarImage
                                                    src={userInfo.image}
                                                    alt='Profile'
                                                />
                                              : <IconCard
                                                    background='#FEF9E5'
                                                    color='#FFAB01'
                                                    style={{
                                                      fontWeight: 800
                                                    }}
                                                >
                                                    {userInfo.firstName ? userInfo.firstName[0].toUpperCase() : ''}
                                                </IconCard>
                                        }
                                        <div>
                                            <span>User</span>
                                            <h3>{`${userInfo.firstName} ${userInfo.surname}`}</h3>
                                            <p>{userInfo.email}</p>
                                        </div>
                                    </UserProfileCard>
                                    <GridTexts>
                                        <p>Created</p>
                                        <h3>{moment(userInfo.timeCreated).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Last Updated KYC</p>
                                        <h3>{userInfo.idDoc === 0 ? 'Not Updated' : moment(userInfo.user_updated_at).format('LL')}</h3>
                                    </GridTexts>
                                    <GridTexts>
                                        <p>Status</p>
                                        <h3
                                            style={{
                                              color: '#ffab01'
                                            }}
                                        >{getKycStatus(userInfo.idDoc)}</h3>
                                    </GridTexts>
                                    <BalanceCard>
                                        <IconCard background='#4DC736' color='#fff'>
                                            &#8358;
                                        </IconCard>
                                        <GridTexts>
                                            <p>Balance</p>
                                            <h3>&#8358;{CommaNumber(userInfo ? userInfo.walletBalance : '')}</h3>
                                        </GridTexts>
                                    </BalanceCard>
                                </div>
                                <SpaceWrap>

                                </SpaceWrap>
                            </UserCard><TableWrap>
                                <TableFlex>
                                <DateWrap>
                                    <h3>Date and Time</h3>
                                </DateWrap>
                                <TableName>
                                    <h3>User</h3>
                                </TableName>
                                <SmallTableSpace>
                                    <h3>Device</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>Message</h3>
                                </SmallTableSpace>
                                <SmallTableSpace>
                                    <h3>IP</h3>
                                </SmallTableSpace>
                                </TableFlex>
                            <Line />
                            {
                                audits !== ''
                                  ? auditsMutable && auditsMutable.length > 0
                                    ? <>
                                        {
                                            auditsMutable.map((item: any, index: number) => (
                                                <TableFlex
                                                    key={index}
                                                >
                                                    <DateWrap>
                                                        <p>{moment(item.loginAt).format('LLL')}</p>
                                                    </DateWrap>
                                                    <TableName>
                                                        {
                                                            item.email
                                                              ? <div>
                                                                        <p>{item.email}</p>
                                                                    </div>
                                                              : <p>No Admin Information</p>
                                                        }
                                                    </TableName>
                                                    <SmallTableSpace>
                                                        <p>{item.device}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <p>{item.message}</p>
                                                    </SmallTableSpace>
                                                    <SmallTableSpace>
                                                        <p>{item.ip ? item.ip : ''}</p>
                                                    </SmallTableSpace>
                                                </TableFlex>
                                            ))
                                        }
                                    </>
                                    : <EmptyState />
                                  : <Loader />
                            }
                        </TableWrap>
                        </>
                              : <Loader />
                        }
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
  )
}

export default AuditTrailComp
