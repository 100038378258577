import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const PreventToDefault = () => {
  const loggedUser = localStorage.getItem('loggedAdmin')
  return loggedUser ? <Outlet /> : <Navigate to="/login" />
}

export const PreventToAuth = () => {
  const loggedUser = localStorage.getItem('loggedAdmin')
  return loggedUser ? <Navigate to="/" /> : <Outlet />
}
