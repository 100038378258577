import React from 'react'
import CreateSuperAdmin from '../components/createSuperAdmin'

const CreateSuperAdminPage = () => {
  return (
        <>
            <CreateSuperAdmin />
        </>
  )
}

export default CreateSuperAdminPage
