export const states: any = [
  { code: 'AB', name: 'Abia', subdivision: null },
  { code: 'AD', name: 'Adamawa', subdivision: null },
  { code: 'AK', name: 'Akwa Ibom', subdivision: null },
  { code: 'AN', name: 'Anambra', subdivision: null },
  { code: 'BA', name: 'Bauchi', subdivision: null },
  { code: 'BY', name: 'Bayelsa', subdivision: null },
  { code: 'BE', name: 'Benue', subdivision: null },
  { code: 'BO', name: 'Borno', subdivision: null },
  { code: 'CR', name: 'Cross River', subdivision: null },
  { code: 'DE', name: 'Delta', subdivision: null },
  { code: 'EB', name: 'Ebonyi', subdivision: null },
  { code: 'ED', name: 'Edo', subdivision: null },
  { code: 'EK', name: 'Ekiti', subdivision: null },
  { code: 'EN', name: 'Enugu', subdivision: null },
  { code: 'FC', name: 'FCT - Abuja', subdivision: null },
  { code: 'GO', name: 'Gombe', subdivision: null },
  { code: 'IM', name: 'Imo', subdivision: null },
  { code: 'JI', name: 'Jigawa', subdivision: null },
  { code: 'KD', name: 'Kaduna', subdivision: null },
  { code: 'KN', name: 'Kano', subdivision: null },
  { code: 'KT', name: 'Katsina', subdivision: null },
  { code: 'KE', name: 'Kebbi', subdivision: null },
  { code: 'KO', name: 'Kogi', subdivision: null },
  { code: 'KW', name: 'Kwara', subdivision: null },
  { code: 'LA', name: 'Lagos', subdivision: null },
  { code: 'NA', name: 'Nassarawa', subdivision: null },
  { code: 'NI', name: 'Niger', subdivision: null },
  { code: 'OG', name: 'Ogun', subdivision: null },
  { code: 'ON', name: 'Ondo', subdivision: null },
  { code: 'OS', name: 'Osun', subdivision: null },
  { code: 'OY', name: 'Oyo', subdivision: null },
  { code: 'PL', name: 'Plateau', subdivision: null },
  { code: 'RI', name: 'Rivers', subdivision: null },
  { code: 'SO', name: 'Sokoto', subdivision: null },
  { code: 'TA', name: 'Taraba', subdivision: null },
  { code: 'YO', name: 'Yobe', subdivision: null },
  { code: 'ZA', name: 'Zamfara', subdivision: null }
]
