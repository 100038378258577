import React from 'react'
import Login from '../components/Login/index'
import Messages from '../components/chat'

const ChatMainPage = () => {
  return (
        <>
            <Messages />
        </>
  )
}

export default ChatMainPage
