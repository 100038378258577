import React from 'react'
import Overview from '../components/dashboard'

const Dashboard = ({ openNav, toggleNavBar }: any) => {
  return (
        <>
            <Overview />
        </>
  )
}

export default Dashboard
