import React from 'react'
import AccountComp from '../components/profile/account'

const AccountPage = () => {
  return (
        <>
            <AccountComp />
        </>
  )
}

export default AccountPage
